import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';

@Component({
  selector: 'app-event-error',
  templateUrl: './event-error.page.html',
  styleUrls: ['./event-error.page.scss'],
})
export class EventErrorPage implements OnInit {

  event = null;
  msg = null;
  
  constructor(private router : Router) { 
    this.event = this.router.getCurrentNavigation().extras.state.event;
		this.msg = this.router.getCurrentNavigation().extras.state.msg;
  }

  ngOnInit() {

  }
}
