import { Component, OnInit } from '@angular/core';
import { AlertController, ToastController } from '@ionic/angular';
import { Router, NavigationExtras } from '@angular/router';
import { SignPage } from '../sign/sign.page';
//import { KioskPage } from '../kiosk/kiosk';
//import { CompletePage } from '../complete/complete';
//import { EventErrorPage } from '../event-error/event-error';
import { KioskService } from '../services/kiosk.service';
import { EventsService } from '../services/events.service';

@Component({
  selector: 'page-list',
  templateUrl: './waivers.page.html',
  styleUrls: ['./waivers.page.scss'],
})

export class WaiversPage {

  bundleDocs = [];
  currentBundleWaiver = 0;
  waiverDocs = [];
  statesData = [];
  countriesData = [];
  relationshipData = [];
  publicLink = false;
  eventLink = false;
  bundleLink = false;
  showWaivers = false;
  event = {};
  paramMap = null;
  prevData = null;
  currBundle = null;

  waiverJustSignedEvent = null;
  waiverSignedEvent = null;
  bundleSignedEvent = null;
  kioskLoadedEvent = null;

  constructor(public router: Router, public kioskService: KioskService,
    public alertCtrl: AlertController, public events: EventsService, public toastCtrl: ToastController) {

    console.log('loading waiver constructure');

    this.bundleSignedEvent = this.events.subscribe('BundleSigned', (val: any) => {
      this.currentBundleWaiver++;
      console.log(event, this.currentBundleWaiver, this.currBundle.waiverDocs.length)
      if (this.currentBundleWaiver < this.currBundle.waiverDocs.length) {
        console.log('Alert')
        this.bundleAlert(this.currBundle, val);
      } else {
        console.log('Complete')
        this.bundleComplete();
      }
    });

    //console.log('Subscribbing')
    this.waiverJustSignedEvent = this.events.subscribe('WaiverJustSigned', (val: any) => {
      //console.log('publicLink', this.publicLink);
      //console.log('waiverDocs.length', this.waiverDocs.length);
      /*	if ((this.publicLink == false) && (this.waiverDocs.length == 1)) {
          setTimeout(() => {
            this.itemTapped(null, this.waiverDocs[0]);
          }, 1000);
        }*/
    });

    this.kioskLoadedEvent = this.events.subscribe('KIOSKLoaded', () => {
      //console.log("unsubscribing");
      //this.waiverJustSignedEvent.unsubscribe();
    });

  }

  ionViewDidEnter() {
    console.log('ionviewdidenter');

    var url = location.search;
    if (url.length > 0) {
      // event : /?a=aHR0cHM6Ly9kZXYuMXJpc2suaW5mby90ZXN0YXBpfDcyNmMwYjVhMTcwMDQxMmRiZmFiNjUzNjQ3MDc4ODQ5fDM2NjU1MDM0LTg4OTItNGNiNi04NjcwLTk5NDMzNGUzNzhmMXwwOS8wNS8yMDE5IDA4OjQ1IEFNfDExLzAxLzIwMTkgMTI6MDAgUE0=

      // event old expired: ?a=aHR0cHM6Ly9teS4xcmlzay5uZXQvdGVzdGFwaXw3MjZjMGI1YTE3MDA0MTJkYmZhYjY1MzY0NzA3ODg0OXw1YTJiMzVjYy1lM2Y3LTRiZjEtODQ0YS01ZGI1OTVlYTA4YTV8MDcvMjIvMjAxOSAwMzowMCBQTXwwOC8zMS8yMDE5IDEyOjAwIFBN
      //?a=aHR0cHM6Ly9kZXYuMXJpc2suaW5mby90ZXN0YXBpfDcyNmMwYjVhMTcwMDQxMmRiZmFiNjUzNjQ3MDc4ODQ5fDM1
      //?a=aHR0cHM6Ly9kZXYuMXJpc2suaW5mby90ZXN0YXBpfDcyNmMwYjVhMTcwMDQxMmRiZmFiNjUzNjQ3MDc4ODQ5fDU3
      //STI ?a=aHR0cHM6Ly9teS4xcmlzay5uZXQvc3RpYXBpfDI4ODNlMjQ1ZTNmMDRiOGU5MzBmMmU3N2U1ZGMwMWE3fDI=
      //?a=aHR0cHM6Ly9kZXYuMXJpc2suaW5mby90ZXN0YXBpfDcyNmMwYjVhMTcwMDQxMmRiZmFiNjUzNjQ3MDc4ODQ5fDU3N2EwNjIwLTkxNmMtNGVmOC1iMGI2LTM0YjQ5YzQyYTdlYXxUaHUgTWFyIDAyIDIwMTcgMDk6MDA6MDAgR01ULTA1MDAgKEVTVCl8U2F0IEFwciAwMSAyMDE3IDEwOjAwOjAwIEdNVC0wNDAwIChFRFQp
      //?a=aHR0cHM6Ly9kZXYuMXJpc2suaW5mby90ZXN0YXBpfDcyNmMwYjVhMTcwMDQxMmRiZmFiNjUzNjQ3MDc4ODQ5fDkyZWVmZDdhLWM0OTctNDFkOS04MGMzLTY0Y2FjODZiN2I2MHxNb24gSnVsIDAzIDIwMTcgMTc6MzQ6MDAgR01ULTA0MDAgKEVEVCl8U2F0IERlYyAwMSAyMDE4IDA4OjAwOjAwIEdNVC0wNTAwIChFU1Qp
      //timber bundle  ?a=aHR0cHM6Ly9teS4xcmlzay5uZXQvdGltYmVybGluZWFwaXxkN2IxNjRiZTQxYTg0MWRmYmUyZTU3NWU2OTA1MWE5Y3w3OTY3YTFmOS1lZjI1LTRlZDctOWRkYi0zZTFlNjMyMmVlM2N8VHVlIEp1biAyMCAyMDE3IDEzOjE1OjAwIEdNVC0wNDAwIChIYWl0aSBEYXlsaWdodCBUaW1lKXxNb24gU2VwIDA0IDIwMTcgMTI6MDA6MDAgR01ULTA0MDAgKEhhaXRpIERheWxpZ2h0IFRpbWUp

      var key = url.substr(3)
      //include trailing equals
      key = key.replace(/%3D/g, '');
      console.log(key);
      var decodedStr = atob(key);
      var parts = decodedStr.split('|');
      var waivers = [];
      console.log('URL Parts', parts);
      this.publicLink = true;
      if (parts.length === 5) {
        this.eventLink = true;
        this.paramMap = { waivers: [], url: parts[0], key: parts[1], eventId: parts[2], start: parts[3], end: parts[4] };
        // for testing start date check: this.paramMap.start = 1577511300000;
        var now = new Date().getTime() as any;
        console.log('EVENT CHEK Start', this.paramMap.start)
        console.log('EVENT CHECK NOW', now);
        if (new Date(this.paramMap.start) > now) {
          console.log('Event Not Available');
          this.router.navigate(['/event-error'], { state: { event: this.paramMap, msg: 'Event is not accepting Waivers' } });
        } else if (new Date(this.paramMap.end) < now) {
          console.log('Event Expired');
          this.router.navigate(['/event-error'], { state: { event: this.paramMap, msg: 'Event is not accepting Waivers' } });
        } else {
          console.log('Event Waiver', this.paramMap);
          this.loadWaiversforEvent(this.paramMap);
        }
      } else if (parts.length === 6) {
        this.eventLink = true;
        this.paramMap = { waivers: [], url: parts[0], key: parts[1], eventId: parts[2], start: parts[3], end: parts[4] };
        // for testing start date check: this.paramMap.start = 1577511300000;
        var now = new Date().getTime() as any;
        console.log('EVENT CHEK Start', this.paramMap.start)
        console.log('EVENT CHECK NOW', now);
        if (new Date(this.paramMap.start) > now) {
          console.log('Event Not Available');
          this.router.navigate(['/event-error'], { state: { event: this.paramMap, msg: 'Event is not accepting Waivers' } });
        } else if (new Date(this.paramMap.end) < now) {
          console.log('Event Expired');
          this.router.navigate(['/event-error'], { state: { event: this.paramMap, msg: 'Event is not accepting Waivers' } });
        } else {
          console.log('Event Waiver', this.paramMap);
          this.paramMap.url = this.paramMap.url.replace('rsg.1risk.info', 'my.1risk.net');
          this.loadGMWaiversforEvent(this.paramMap);
        }
      } else {
        waivers.push(parts[2]);
        this.paramMap = { waivers: waivers, url: parts[0], key: parts[1], eventId: '' };
        this.loadWaiversforPublicURL(this.paramMap);
      }

      this.kioskService.getlogoforPublic(this.paramMap);

    } else {
      console.log("loading docs");
      this.kioskService.loadWaiverDocs().then(data => {
        this.waiverDocs = data as any;
        //console.log(this.waiverDocs)
        this.kioskService.loadBundleDocs().then(data => {
          this.bundleDocs = data as any;
          //console.log(this.waiverDocs)
          //console.log(this.bundleDocs)
          if ((this.waiverDocs == null) && (this.bundleDocs == null)) {
            this.router.navigate(['/kiosk']);
          } else {
            this.kioskService.loadKeys().then(val => {
              var x = val as any;
              if (x.apiName == '') {
                this.router.navigate(['/kiosk']);
              } else {
                this.kioskService.getlogo();

                this.kioskService.loadLookup('states').then(data => {
                  this.statesData = data as any;
                  this.kioskService.loadLookup('countries').then(data => {
                    this.countriesData = data as any;
                    this.kioskService.loadLookup('relationships').then(data => {
                      this.relationshipData = data as any;
                      if ((this.waiverDocs) && (this.waiverDocs.length == 1) && !this.bundleDocs) {
                        console.log('waiverDocs.length', this.waiverDocs.length);
                        this.itemTapped(null, this.waiverDocs[0]);
                      } else {
                        this.showWaivers = true;
                      }
                    });
                  });
                });
              }
            })
          }


        });

      });
    }
  }

  ionViewDidLeave() {
    console.log('DID LEAVE')
    //this.waiverJustSignedEvent.unsubscribe();
    //this.bundleSignedEvent.unsubscribe();
    //if(this.waiverSignedEvent){
    //		this.waiverSignedEvent.unsubscribe();
    //	}
    //	this.kioskLoadedEvent.unsubscribe();
    //http://localhost:8100?a=aHR0cHM6Ly9kZXYuMXJpc2suaW5mby90ZXN0YXBpfDcyNmMwYjVhMTcwMDQxMmRiZmFiNjUzNjQ3MDc4ODQ5fDE=
  }

  bundleTapped(event, bundle) {
    this.currentBundleWaiver = 0;
    console.log('BundleTapped', bundle)
    this.currBundle = bundle;
    this.signNextWaiverInBundle(bundle, this.currentBundleWaiver, null)

  }

  signNextWaiverInBundle(bundle, next, prevData) {
    console.log('signNextWaiverInBundle', prevData);

    let navigationExtras: NavigationExtras = {
      state: {
        item: bundle.waiverDocs[this.calcBundleIndex(bundle, bundle.waiverBundles[next].document)],
        countriesData: this.countriesData,
        statesData: this.statesData,
        relationshipData: this.relationshipData,
        bundle: true,
        prevData: prevData
      }
    }
    this.router.navigate(['/sign'], navigationExtras);
  }

  calcBundleIndex(bundle, bundleId) {
    for (var i = 0; i < bundle.waiverDocs.length; i++) {
      if (bundle.waiverDocs[i].id == bundleId) {
        return i;
      }
    }
    return 0;
  }

  async bundleAlert(bundle, prevData) {
    const alert = await this.alertCtrl.create({
      header: 'Complete',
      message: 'Do you want to sign the next Waiver?  ' + bundle.waiverDocs[this.calcBundleIndex(bundle, this.currentBundleWaiver)].name,
      backdropDismiss: false,
      buttons: [

        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            this.currentBundleWaiver++;
            if (this.currentBundleWaiver < bundle.waiverDocs.length) {
              this.bundleAlert(bundle, prevData);
            } else {
              this.bundleComplete();
            }
          }
        }, {
          text: 'Yes',
          role: 'yes',
          handler: () => {
            this.signNextWaiverInBundle(bundle, this.currentBundleWaiver, prevData)
          }
        }
      ]
    })
    alert.present();
  }

  async bundleComplete() {
    const alert = await this.alertCtrl.create({
      message: 'You have signed all the Waivers in this Bundle.',
      buttons: ['Ok'],
    });
    alert.present();
  }

  itemTapped(event, item) {

    let navigationExtras: NavigationExtras = {
      state: {
        item: item,
        countriesData: this.countriesData,
        statesData: this.statesData,
        relationshipData: this.relationshipData,
      }
    }
    console.log(navigationExtras);
    this.router.navigate(['/sign'], navigationExtras);
  }

  loadWaiversforEvent(key) {
    this.kioskService.loadWaiverDocsForEvent(key).then(data => {
      this.waiverDocs = data as any;
      this.kioskService.pullLookupsFromPublic(key, 'states').then(data => {
        this.statesData = data as any;
        this.kioskService.pullLookupsFromPublic(key, 'countries').then(data => {
          this.countriesData = data as any;
          this.kioskService.pullLookupsFromPublic(key, 'relationships').then(data => {
            this.relationshipData = data as any;
            if (this.waiverDocs.length > 1) {
              this.bundleLink = true;
            }
            var msgAddition = '';
            if (this.paramMap.waivers.length > 1) {
              msgAddition += '. There are ' + this.paramMap.waivers.length + ' Waivers to sign.';
            }
            this.eventAlert(this.paramMap.eventData[0].eventName, msgAddition);
            this.completePublicWaiver();

          });
        });
      });

      this.waiverSignedEvent = this.events.subscribe('WaiverSigned', (val: any) => {
        console.log('Subscribe WaiverSigned');
        this.signingComplete(val);
      });

    });
  }

  loadGMWaiversforEvent(key) {
    this.kioskService.loadGMWaiverDocsForEvent(key).then(data => {
      this.waiverDocs = data as any;
      this.kioskService.pullLookupsFromPublic(key, 'states').then(data => {
        this.statesData = data as any;
        this.kioskService.pullLookupsFromPublic(key, 'countries').then(data => {
          this.countriesData = data as any;
          this.kioskService.pullLookupsFromPublic(key, 'relationships').then(data => {
            this.relationshipData = data as any;
            if (this.waiverDocs.length > 1) {
              this.bundleLink = true;
            }
            var msgAddition = '';
            if (this.paramMap.waivers.length > 1) {
              msgAddition += '. There are ' + this.paramMap.waivers.length + ' Waivers to sign.';
            }
            this.eventAlert(this.paramMap.eventData[0].eventName, msgAddition);
            this.completePublicWaiver();

          });
        });
      });

      this.waiverSignedEvent = this.events.subscribe('WaiverSigned', (val: any) => {
        console.log('Subscribe WaiverSigned');
        this.signingComplete(val);
      });

    });
  }  

  async eventAlert(header, msgAddition) {
    const alert = await this.alertCtrl.create({
      header: header,
      message: 'You are signing this Waiver for an Event' + msgAddition,
      buttons: ['Ok']
    })
    alert.present();
  }

  loadWaiversforPublicURL(key) {
    this.kioskService.loadWaiverDocsFromPublic(key).then(data => {
      this.waiverDocs = data as any;
      if (this.waiverDocs[0].collectAddress) {
        this.kioskService.pullLookupsFromPublic(key, 'states').then(data => {
          this.statesData = data as any;
          this.kioskService.pullLookupsFromPublic(key, 'countries').then(data => {
            this.countriesData = data as any;
            if (this.waiverDocs[0].collectEci) {
              this.kioskService.pullLookupsFromPublic(key, 'relationships').then(data => {
                this.relationshipData = data as any;
                this.completePublicWaiver();
              });
            } else {
              this.completePublicWaiver();
            }
          });
        });
      } else if (this.waiverDocs[0].collectEci) {
        this.kioskService.pullLookupsFromPublic(key, 'relationships').then(data => {
          this.relationshipData = data as any;
          this.completePublicWaiver();
        });
      } else {
        this.completePublicWaiver();
      }

      this.events.subscribe('WaiverSigned', (val: any) => {
        console.log('Subscribe WaiverSigned - 283')
        this.signingComplete(val);
      });

    });
  }

  completePublicWaiver() {

    if (this.waiverDocs[0].name) {
      
      let navigationExtras: NavigationExtras = {
        queryParams: { "a": location.search.substr(3).replace(/%3D/g, '')},
        state: {
          item: this.waiverDocs[0],
          countriesData: this.countriesData,
          statesData: this.statesData,
          relationshipData: this.relationshipData,
          eventUid: this.paramMap.eventId,
          prevData: this.prevData,
          paramMap: this.paramMap,
          loadedFromLink: this.publicLink,
        }
      }
      
      this.router.navigate(['/sign'], navigationExtras);
    }
  }

  signingComplete(prevData) {
    this.prevData = prevData;
    console.log('waiver.ts: signingComplete');

    if (this.publicLink && !this.paramMap.eventId) {
      this.router.navigate(['/complete']);
      console.log("here");
    } else if (this.publicLink && this.paramMap.eventId) {
      console.log(prevData);
      console.log(this.waiverDocs);
      for (var i = 0; i < this.waiverDocs.length; i++) {
        if (prevData.documentId == this.waiverDocs[i].id) {
          this.waiverDocs.splice(i, 1);
        }
      }
      console.log(this.waiverDocs);
      console.log(this.waiverDocs.length);
      if (this.waiverDocs.length > 0) {
        this.nextWaiverAlert(this.waiverDocs[0].name, this.waiverDocs[0].id, prevData);
      } else {
        this.finishedWaiverAlert();
      }
    }
  }

  async nextWaiverAlert(name, id, prevData) {
    const alert = await this.alertCtrl.create({
      header: 'Complete',
      message: 'Do you want to sign the next Waiver?  ' + name,
      backdropDismiss: false,
      buttons: [
        {
          text: 'Yes',
          role: 'yes',
          handler: () => {
            this.completePublicWaiver();
          }
        },
        {
          text: 'No',
          role: 'cancel',
          handler: () => {
            prevData.documentId = id;
            this.signingComplete(prevData);
          }
        }
      ]
    })
    alert.present();
  }

  async finishedWaiverAlert() {
    const alert = await this.alertCtrl.create({
      header: 'Complete',
      message: 'Signing completed, you may close this window.',
      backdropDismiss: false
    });
    alert.present();
  }
}
