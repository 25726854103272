/*import { Component, ViewChild } from '@angular/core';
import {  NavController, NavParams, ModalController } from '@ionic/angular';
import { SignaturePad } from 'angular2-signaturepad';
*/
/**
 * Generated class for the SignaturePage page.
 *
 * See http://ionicframework.com/docs/components/#navigation for more info
 * on Ionic pages and navigation.
 */
/*
@Component({
  selector: 'page-signature',
  templateUrl: 'signature.page.html',
  styleUrls: ['./signature.page.scss'],
})
export class SignaturePage {
  @ViewChild(SignaturePad) public signaturePad: SignaturePad;

  public signaturePadOptions: Object = {
    'minWidth': 2,
    'canvasWidth': 550,  //113
    'canvasHeight': 230  // 66
  };
  public signatureImage: string;
  public title: string;

  constructor(public navCtrl: NavController, public navParams: NavParams, public viewCtrl: ModalController) {
    this.signatureImage = navParams.get('sig');
    this.title = navParams.get('title');
  }

  ionViewDidLoad() {
    //console.log('ionViewDidLoad SignaturePage');
  }

  drawCancel() {
    this.signatureImage = this.navParams.get('sig');
    this.viewCtrl.dismiss(this.navParams.get('sig'));
  }

  drawComplete() {
    if (this.signaturePad.isEmpty()) {
      this.signatureImage = null;
    } else {
      this.signatureImage = this.signaturePad.toDataURL();
    }
    this.viewCtrl.dismiss(this.signatureImage);
  }

  drawClear() {
    this.signaturePad.clear();
  }

  canvasResize() {
    let canvas = document.querySelector('canvas');
    this.signaturePad.set('minWidth', 1);
    this.signaturePad.set('canvasWidth', canvas.offsetWidth);
    this.signaturePad.set('canvasHeight', canvas.offsetHeight);
  }

  ngAfterViewInit() {
    //this.signaturePad.clear();
    //this.canvasResize();
  }
}*/

import { Component, OnInit, ViewChild, ElementRef, ViewEncapsulation, AfterViewInit, AfterViewChecked } from '@angular/core';
import { NavParams, ModalController } from '@ionic/angular';
import { SignaturePad } from 'angular2-signaturepad';

@Component({
	selector: 'app-signature',
	templateUrl: './signature.page.html',
	styleUrls: ['./signature.page.scss'],
	encapsulation: ViewEncapsulation.None,
})
export class SignaturePage implements OnInit, AfterViewInit {
	@ViewChild(SignaturePad, { static: true }) signaturePad: SignaturePad;
	@ViewChild('signatureContainer') public signatureContainer;

	private signaturePadOptions  = {
		minWidth: 2,
		canvasWidth: 572,
		canvasHeight: 230
	};

	signatureData: any;
	isEmpty = true;
	title = 'Signature';
	padSidePadding = 14;

	constructor(private navParams: NavParams, private modalCtrl: ModalController) { }

	ngOnInit() {
		// breakpoint for "full width modal"
		if(window.innerWidth < 768){
			this.signaturePadOptions.canvasWidth = window.innerWidth - this.padSidePadding * 2;
		}
	}

	// tslint:disable-next-line:use-lifecycle-interface
	ngAfterViewInit() {
		/* console.log('signature pad ngAfter', this.signaturePad);
		console.log(this.signaturePad.elementRef.nativeElement.parentElement.clientHeight)
		console.log(this.signaturePad.elementRef.nativeElement.parentElement.clientWidth)
		console.log(document.getElementsByTagName('app-signature'));
		*/
		if (this.signaturePad) {
			this.signaturePad.clear();
			this.signatureData = this.navParams.get('sig');
			this.title = this.navParams.get('title');
			if (this.signatureData) {
				this.isEmpty = false;
				this.signaturePad.fromDataURL(this.signatureData);
			}
		}
	}

	resizePad() {
		this.isEmpty = true;
		const that = this;
    this.signaturePad.set('canvasWidth', this.signatureContainer.el.offsetWidth - this.padSidePadding * 2);
	}

	drawComplete() {
		// will be notified of szimek/signature_pad's onEnd event
		console.log(this.signaturePad.toDataURL());
	}

	drawStart() {
		// will be notified of szimek/signature_pad's onBegin event
		this.isEmpty = false;
	}

	drawClear() {
		this.isEmpty = true;
		this.signaturePad.clear();
	}

	drawCancel() {
		this.modalCtrl.dismiss({ result: 'CANCEL' });
	}

	drawSave() {
		if (!this.isEmpty) {
			this.modalCtrl.dismiss({ result: 'UPDATED', sig: this.signaturePad.toDataURL() });
		}
	}
}
