import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';

@Component({
  selector: 'onerisk-datetime',
  templateUrl: './onerisk-datetime.component.html',
  styleUrls: ['./onerisk-datetime.component.scss'],
})

export class OneriskDatetimeComponent implements OnInit {

	@Input('label') label: string;
	@Input('value') value: any;
	@Input('type') type: string;
	@Input('required') required: boolean = false;
	@Output() valueChange: EventEmitter<any> = new EventEmitter<any>();

	//dateFormat = 'DD/MM/YYYY';
	//placeholder = 'dd/mm/yyyy';
	//dateTimeFormat = 'DD/MM/YYYY hh:mm A';

	dateFormat = 'MM/DD/YYYY';
	placeholder = 'mm/dd/yyyy';
	dateTimeFormat = 'MM/DD/YYYY hh:mm A';

	international = false;

	mask = '00/00/0000';
	dateTimeMask = '00/00/0000 h0:m0';
	initValue: any;
	constructor() {
	}

	ngOnInit() {
		const lang = window.navigator.language.toLowerCase();
		if (this.type === 'date') {
			if (lang == "en" || lang == "en-us") {
				this.dateTimeFormat = 'MM/DD/YYYY hh:mm A';
				this.dateFormat = 'MM/DD/YYYY';
				this.placeholder = 'mm/dd/yyyy';
				this.international = false;
			} else {
				this.dateTimeFormat = 'DD/MM/YYYY hh:mm A';
				this.dateFormat = 'DD/MM/YYYY';
				this.placeholder = 'dd/mm/yyyy';
				this.international = true;
			}
			if (this.value) {
				this.value = moment(this.value, this.dateFormat).format(this.dateFormat);
			}
			this.mask = '00/00/0000';
		} else {
			if (lang == "en" || lang == "en-us") {
				this.dateTimeFormat = 'MM/DD/YYYY hh:mm A';
				this.dateFormat = 'MM/DD/YYYY';
				this.placeholder = 'dd/mm/yyyy';
			}
			this.value = moment(this.value).format(this.dateTimeFormat);
			this.mask = '00/00/0000 00:00 XX';
			this.placeholder = 'mm/dd/yyyy hh:mm am';
		}
		this.initValue = this.value
		//console.log('1riskdatetime ngonInit: ', this.value, this.type, this.label, this.required, moment.locale())
  	}


	clearDate() {
		this.value = null;
		this.initValue = this.value;
		this.valueChange.emit(this.value);
		document.getElementById(this.label).focus();
  }

	onKey(event) {
		if (this.value != null && this.value != "" && this.value.length === 8) {
			let inputDate = '';
			inputDate = [this.value.slice(0, 2), '/', this.value.slice(2)].join('');
			inputDate = [inputDate.slice(0, 5), '/', inputDate.slice(5)].join('')
			const dv = moment(inputDate, this.dateFormat);
			console.log(dv);
			if (dv.isValid()) {
				const dateValue = dv.format(this.dateFormat);
				this.initValue = this.value;
				this.valueChange.emit(dateValue);
			}
		}
	}

	onBlur(event) {
		/*if (this.value != null && this.value != "") {
			console.log("hi");
			let inputDate = '';
			inputDate = [this.value.slice(0, 2), '/', this.value.slice(2)].join('');
			inputDate = [inputDate.slice(0, 5), '/', inputDate.slice(5)].join('');
			const dv = moment(inputDate, this.dateFormat);
			console.log(dv);
			if (!dv.isValid()) {
				this.value = null;
				this.valueChange.emit(null);
			} else {
				const dateValue = dv.format(this.dateFormat);
				this.initValue = this.value;
				this.valueChange.emit(dateValue);
			}
		}*/
	}
}
