import { Pipe, PipeTransform } from '@angular/core';
import {CommonModule} from "@angular/common";

/**
 * Generated class for the SortPipe pipe.
 *
 * See https://angular.io/api/core/Pipe for more info on Angular Pipes.
 */
@Pipe({
  name: 'sort',
})
export class SortPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  transform(items: any [],  key: boolean, element: string) {
    if (items) {
      return items
        .filter(it => {
          if (key != null) {
            return it.active === key;
          } else {
            return true;
          }        
        })

        .sort(function (a, b) {
          //console.log(a)
          if (element == 'active') {
            if (a.active) { a = '0' } else { a = '1' };
            if (b.active) { b = '0' } else { b = '1' };
          } else if (element == 'staffType') {
            a = a.staffTypeLabel + ':' + a.lastName.toUpperCase() + ' ' + a.firstName.toUpperCase();
            b = b.staffTypeLabel + ':' + + b.lastName.toUpperCase() + ' ' + b.firstName.toUpperCase();
          } else if (element == 'email') {
            if (a.email != null) {
              a = a.email.toUpperCase();
            } else {
              a = '';
            }
            if (b.email != null) {
              b = b.email.toUpperCase();
            } else {
              b = '';
            }
          } else if (element == 'bundleName') {
            a = a.bundleName.toUpperCase();
            b = b.bundleName.toUpperCase();
          } else if (element == 'code') {
            a = a.code.toUpperCase();
            b = b.code.toUpperCase();
          } else if (element == 'alias') {
            if (a.alias) { a = a.alias.toUpperCase() } else { a = '' };
            if (b.alias) { b = b.alias.toUpperCase() } else { b = '' };
          } else if (element == 'sortOrder') {
            a = a.sortOrder;
            b = b.sortOrder;
          } else if (element == 'lookupName') {
            a = a.sortOrder + ' ' + a.lookupName.toUpperCase();
            b = b.sortOrder + ' ' + b.lookupName.toUpperCase();
           } else {
            a = a.name.toUpperCase();
            b = b.name.toUpperCase();
          }
          var ax = [], bx = [];
          a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
          b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
          while (ax.length && bx.length) {
            var an = ax.shift();
            var bn = bx.shift();
            var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
            if (nn) return nn;
          }
          return ax.length - bx.length;
        });
    }

  }
}

