import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
@Component({
  selector: 'app-video',
  templateUrl: './video.page.html',
  styleUrls: ['./video.page.scss'],
})
export class VideoPage {
  @Input() videoURL: string = '';
  videoMsg = '';
  previousTime = 0;
  videoEle: HTMLVideoElement;
  userSeeked = false;
  userSeekedCnt = 0;

  constructor(public router: Router, public modalCtrl: ModalController) {
    //this.videoMsg = this.navParams.get('msg');
    /*if ((this.videoMsg == null) || (this.videoMsg === '')) {
      this.videoMsg = 'You must watch this video.';
    }*/

  }

  vidEnd(e) {
    console.log('ended', e)
    this.modalCtrl.dismiss();
  }

  played(e) {
    this.videoEle.controls = false;
  }
  timeUpdate(e) {
  }
  timeUpdate2(e) {
    console.log(e.target.currentTime, e)
    this.userSeeked = true;
    var delta = e.target.currentTime - this.previousTime;
    //console.log(delta);
    if (delta > 1) {
      //console.log('seeking')
      e.target.currentTime = this.previousTime;
    } else {
      this.previousTime = e.target.currentTime;
    }
  }

  seeking(e) {
    console.log('seeking', e.target.currentTime);
    this.reloadVid();
  }

  reloadVid() {
    //this.videoEle = document.getElementById("vid") as HTMLVideoElement; 
    this.videoEle.load();
  }

  ionViewDidEnter() {
    console.log('ionViewDidLoad Video Page');
    this.videoEle = document.getElementById("vid") as HTMLVideoElement;
    this.videoEle.controls = false;
  }

  closeModal() {
    //this.viewCtrl.dismiss();
  }
}