import { Component, AfterViewInit, ViewChildren, QueryList, Input, Output, EventEmitter } from '@angular/core';
import { IonTabBar, IonTabButton } from '@ionic/angular';

@Component({
	selector: 'status-tabs',
	templateUrl: './status-tabs.component.html',
	styleUrls: ['./status-tabs.component.scss']
})
export class StatusTabsComponent implements AfterViewInit {
	@ViewChildren('statusBar') statusBar: QueryList<IonTabButton>;
	@Output() tabClicked: EventEmitter<any> = new EventEmitter();
	@Input() tabs: Array<any>;
	/*tabs = [
		{tabName: "Initial", sectionId: "#waiver-card"},
		{tabName: "Consent", sectionId: "#consent-card"},
		{tabName: "Sign", sectionId: "#sign-card"},
		{tabName: "Submit", sectionId: "#submit-card"}
	];*/

	currentTabNumber = 0;
	numberOfTabs = 0;

	constructor() { }

	ngAfterViewInit() {
		this.numberOfTabs = this.statusBar.toArray().length;
		this.setCurrentTabNumber(0);
	}

	tabIsCurrent(tabNumber) {
		return tabNumber === this.currentTabNumber;
	}

	tabIsCompleted(tabNumber) {
		return tabNumber < this.currentTabNumber;
	}

	setCurrentTabNumber(tabNumber) {
		for (let i = 0; i < tabNumber; i++) {
			this.statusBar.toArray()[i].selected = false;
		}
		if (tabNumber < this.numberOfTabs) {
			this.statusBar.toArray()[tabNumber].selected = true;
			this.statusBar.toArray()[tabNumber].disabled = false;
		}
		for (let i = tabNumber + 1; i < this.numberOfTabs; i++) {
			this.statusBar.toArray()[i].selected = false;
			this.statusBar.toArray()[i].disabled = true;
		}

		this.currentTabNumber = tabNumber;
	}

	updateCurrentTab(tabName) {
		this.setCurrentTabNumber(this.tabNumberByName(tabName));
	}

	tabsFinished() {
		this.setCurrentTabNumber(this.numberOfTabs);
	}

	tabNameByNumber(tabNumber) {
		return this.tabs[tabNumber].tabName;
	}

	tabNumberByName(tabName) {
		for (let i = 0; i < this.numberOfTabs; i++) {
			if (this.tabs[i].tabName === tabName) {
				return i;
			}
		}
	}

	tabWasClicked(sectionId) {
		this.tabClicked.emit([sectionId]);
	}
}
