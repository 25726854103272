import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Router } from '@angular/router';


@Component({
  selector: 'app-stop-sign',
  templateUrl: './stop-sign.page.html',
  styleUrls: ['./stop-sign.page.scss'],
})
export class StopSignPage {
  @Input() msg: string = '';

  constructor(private router: Router, private modalCtrl: ModalController) {

  }

  ionViewWillEnter() {
    if ((this.msg == null) || (this.msg === '')) {
      this.msg = 'Please read this waiver carefully!';
    }
  }

  closeModal() {
    this.modalCtrl.dismiss();
  }
}