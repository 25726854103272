/* tslint:disable */
import { Component, OnInit, ViewChild } from '@angular/core';
import { NavController, NavParams, ModalController, Platform, LoadingController, AlertController, IonContent } from '@ionic/angular';
import { Camera, CameraOptions } from '@ionic-native/camera/ngx';
import { Storage } from '@ionic/storage';
import { SignedWaiverService } from '../services/signed-waiver.service';
import { StopSignPage } from '../stop-sign/stop-sign.page';
import { VideoPage } from '../video/video.page';
import { SignaturePage } from '../signature/signature.page';
import { DomSanitizer } from '@angular/platform-browser';
import { KioskService } from '../services/kiosk.service';
import { WebCamComponent } from 'ack-angular-webcam';
import { Router, ActivatedRoute, ParamMap, NavigationExtras} from '@angular/router';
import { EventsService } from '../services/events.service';
//import { SanatizeHtmlPipe } from '../pipes/sanatize-html.pipe';
import { StatusTabsComponent } from '../components/status-tabs/status-tabs.component';
import * as moment from 'moment';

@Component({
	selector: 'page-list',
	templateUrl: './sign.page.html',
	styleUrls: ['./sign.page.scss'],
})

export class SignPage {
	@ViewChild(IonContent) content: IonContent;
	@ViewChild('statusTabs') statusTabs: StatusTabsComponent;

	bundle = null;
	waiverDef: any;
	guestType = null;
	showGuestType = true;
	statesData = null;
	countriesData = null;
	relationshipData = null;
	loadedFromLink = false;
	minorCount = 1;
	questions = [];
	validForm = false;
	validMessage = '';
	subInitials = [];
	subInitialsOK = true;
	body = [];
	trustedBody;
	eventUid = '';
	dobSpinner = true;
	paramMap = null;
	keyList = [];
	index = 0;
	finished = false;  //staff verification process
	agreeToTerms = false;
	questionsInvalid = false;
	minorsInvalid = false;
	adultInvalid = false;

	currentCountry = null;
	currentMinor = null;
	currentGuest = {
		initialed: false,
		adult: false,
		minors: false,
		questions: false,
		consent: false,
		signed: false,
		submitted: false,
		showInitials: true,
		showPhoto: false,
		showAdult: false,
		showMinor: false,
		showQuestions: false,
		showConsent: false,
		showSign: false,
		showSubmit: false
	}

	readonly sections = [{ name: 'Waiver' }, { name: 'My Photo' }];
	shownSection = 0;
	readonly cameraOptions: CameraOptions = {
		quality: 100,
		destinationType: this.camera.DestinationType.DATA_URL,
		encodingType: this.camera.EncodingType.JPEG,
		mediaType: this.camera.MediaType.PICTURE,
		cameraDirection: this.camera.Direction.FRONT
	}

	statusTabsConfig = [
		{ tabName: 'Initial', sectionId: '#waiver-card' },
		{ tabName: 'Add Info', sectionId: '#info-card' },
		{ tabName: 'Consent', sectionId: '#consent-card' },
		{ tabName: 'Sign', sectionId: '#sign-card' },
		{ tabName: 'Submit', sectionId: '#submit-card' },
	];

	nativeDevice = false;
	webcam: WebCamComponent //will be populated by <ack-webcam [(ref)]="webcam">
	base64;
	facingMode: string = 'user'

	cameraAvailable: boolean = false;
	multipleDevicesAvailable: boolean = false;
	mime = 'image/jpeg';

	options = {
		audio: false,
		video: true,
		width: 280,
		height: 210,
		fallbackMode: 'callback',
		fallbackSrc: 'jscam_canvas_only.swf',
		fallbackQuality: 85,
		cameraType: 'back'
	};

	videoDevices: MediaDeviceInfo[];

	dateOfBirth2 = '';
	prevData = null;

	constructor(public router: Router, private route: ActivatedRoute, public navCtrl: NavController, /*public navParams: NavParams,*/ public modalCtrl: ModalController, private camera: Camera,
		private platform: Platform, private alertCtrl: AlertController, private storage: Storage,
		private signedDoc: SignedWaiverService, private domSanitizer: DomSanitizer,
		private events: EventsService, public kioskService: KioskService, public loadingCtrl: LoadingController) {
		if (this.platform.is('desktop')) {
			this.dobSpinner = false;
		}

		this.signedDoc.new();
		if(!this.router.getCurrentNavigation().extras.state){
			if(location.search.substr(3)){
			let navigationExtras: NavigationExtras = {
				queryParams: { "a": location.search.substr(3).replace(/%3D/g, '')}}
				router.navigate(["/waivers"], navigationExtras);
			}else{
				router.navigate(["/waivers"]);
			}
		}

		this.bundle = this.router.getCurrentNavigation().extras.state.bundle;
		if (this.bundle) {
			console.log('isBundle', this.bundle)
		}
		this.waiverDef = this.router.getCurrentNavigation().extras.state.item;
		this.statesData = this.router.getCurrentNavigation().extras.state.statesData;
		this.countriesData = this.router.getCurrentNavigation().extras.state.countriesData;
		this.relationshipData = this.router.getCurrentNavigation().extras.state.relationshipData;
		this.eventUid = this.router.getCurrentNavigation().extras.state.eventUid;
		this.loadedFromLink = this.router.getCurrentNavigation().extras.state.loadedFromLink;
		this.signedDoc.eventUid = this.eventUid;
		//console.log(this.statesData);

		this.prevData = this.router.getCurrentNavigation().extras.state.prevData;
		this.paramMap = this.router.getCurrentNavigation().extras.state.paramMap;

		if (this.prevData) {
			//console.log('Prev Data', prevData, this.signedDoc.currentCountry)
			this.signedDoc.fillFromPrevData(this.prevData);
			this.signedDoc.country = this.signedDoc.currentCountry;
			if(this.signedDoc.dateOfBirth){
				this.signedDoc.dateOfBirth = moment(this.signedDoc.dateOfBirth, "YYYY-MM-DD").format(this.signedDoc.dateFormat);
			}
			//console.log(this.signedDoc.dateOfBirth);
			if (this.prevData.minors.length > 0) {
				this.minorCount = this.prevData.minors.length;
				this.guestType = 2;
				this.currentGuest.showMinor = true;
				if (this.prevData.guardianName != null) {
					this.guestType = 3;
					this.signedDoc.lastName = this.prevData.guardianLastName;
					this.signedDoc.firstName = this.prevData.guardianFirstName;
					this.signedDoc.email = this.prevData.guardianEmail;
					this.signedDoc.phone = this.prevData.guardianPhone;
					//this.signedDoc.dateOfBirth = prevData.guardianDob;
					if(this.prevData.guardianDob){
						this.signedDoc.dateOfBirth = moment(this.prevData.guardianDob, "YYYY-MM-DD").format(this.signedDoc.dateFormat);
					}
				}
				for (let i = 0; i < this.prevData.minors.length; i++) {
					this.signedDoc.minors[i].signature = null;
					this.currentMinor = 0;
					if(this.prevData.minors[i].dateOfBirth){
						this.signedDoc.minors[i].dateOfBirth = moment(this.prevData.minors[i].dateOfBirth, "YYYY-MM-DD").format(this.signedDoc.dateFormat);
					}
				}

			}
			if (this.waiverDef.minorsOnly === null) {
				this.waiverDef.minorsOnly = false;
			}
			/*
			console.log(this.signedDoc);
			console.log('values');
			console.log(this.guestType)
			console.log(this.waiverDef.minorsOnly)
			console.log(this.currentGuest.showAdult)
			console.log(this.guestType && !this.waiverDef.minorsOnly )
			console.log(this.waiverDef.minorsOnly && this.currentGuest.showAdult)
			console.log(this.guestType && !this.waiverDef.minorsOnly || this.waiverDef.minorsOnly && this.currentGuest.showAdult)
			*/
		}


		if (!this.waiverDef.maxMinors) {
			this.waiverDef.maxMinors = 100;
		} else {
			if (this.waiverDef.maxMinors === 1) {
				//this.currentMinor = 1;
				//this.signedDoc.addMinor();
			}
		}

		this.showGuestType = true;
		if (this.waiverDef.minorsOnly) {
			this.showGuestType = false;
			this.guestType = 3;
			this.guestTypeChange();
			this.signedDoc.currentCountry = this.signedDoc.country;
			if (!this.prevData) {
				console.log('MinorsOnly, Skipping')
				this.signedDoc.addMinor();
			}
			this.currentMinor = 0;
			if (!this.prevData) {
				this.minorCount = 1;
				console.log('calling minorCountChange')
				this.minorCountChange();
			}
			console.log('set GT to 3', this.signedDoc)
		}

		//console.log(this.waiverDef.customFields[0]);

		this.buildQuestions(this.waiverDef.customFields[0].f1, this.waiverDef.customFields[0].requireF1).then(val => {
			this.buildQuestions(this.waiverDef.customFields[0].f2, this.waiverDef.customFields[0].requireF2).then(val => {
				this.buildQuestions(this.waiverDef.customFields[0].f3, this.waiverDef.customFields[0].requireF3).then(val => {
					this.buildQuestions(this.waiverDef.customFields[0].f4, this.waiverDef.customFields[0].requireF4).then(val => {
						this.buildQuestions(this.waiverDef.customFields[0].f5, this.waiverDef.customFields[0].requireF5).then(val => {
							this.buildQuestions(this.waiverDef.customFields[0].f6, this.waiverDef.customFields[0].requireF6).then(val => {
								this.buildQuestions(this.waiverDef.customFields[0].f7, this.waiverDef.customFields[0].requireF7).then(val => {
									this.buildQuestions(this.waiverDef.customFields[0].f8, this.waiverDef.customFields[0].requireF8).then(val => {
										this.buildQuestions(this.waiverDef.customFields[0].f9, this.waiverDef.customFields[0].requireF9).then(val => {
											this.buildQuestions(this.waiverDef.customFields[0].f10, this.waiverDef.customFields[0].requireF10);
										})
									});
								});
							});
						});
					});
				});
			});
		});

		this.embeddedInitials();
	
		this.showVideo().then((val) => {
			this.showStop();
		});
		/*this.takePhoto().then((val) => {
			if (val) {
				if (this.signedDoc.photo) {
					if (this.signedDoc.photo.indexOf("data:image") == -1) {
						this.signedDoc.photo += 'data:image/jpeg;base64,'
					}
				}
				this.showVideo().then((val) => {
					this.showStop();
				});
			}
		});*/

	}

	ionViewDidLoad() {
		this.setBackButtonAction()
	}

	//Method to override the default back button action
	setBackButtonAction() {
		/*this.navBar.backButtonClick = () => {

			//console.log(this.signedDoc.initials)
			if (this.signedDoc.initials) {
				let alert = this.alertCtrl.create({
					title: 'Go Back?',
					message: 'You will lose all your data.',
					buttons: [
						{ text: 'Yes', handler: () => { this.navCtrl.pop(); } },
						{ text: 'No', role: 'cancel', handler: () => { } }
					]
				});
				alert.present();
			} else {
				this.navCtrl.pop();
			}
		}*/
	}

	embeddedInitials() {
		var sections = this.waiverDef.body.split(/\[\[(.*)\]\]/g);
		if (sections.length > 1) {
			this.subInitialsOK = false;
			for (var i = 0; i < sections.length;) {
				this.body.push(sections[i]);
				if (sections[i + 1]) {
					this.subInitials.push({ initials: null, label: sections[i + 1] });
				}
				i += 2;
			}
		} else {
			this.body.push(sections[0]);
		}
	}

	buildQuestions(q, r) {
		return new Promise(resolve => {
			if ((q != null) && (q != '')) {
				let type = 'text';
				let key = '';
				let label = q;
				let options = [];

				if (label.match(/{(.*)}/) != null) {
					type = 'lookup'
					key = label.match(/{(.*)}/);
					label = label.replace(/{(.*)}/, '');
					if (this.paramMap) {
						var found = false;
						//console.log(this.keyList)
						for (var i = 0; i < this.keyList.length; i++) {
							//console.log(this.keyList);
							if (key[1] == this.keyList[i].key) {
								found = true;
								options = this.keyList[i].options
								this.questions.push({ type: type, label: label, key: key, options: options, required: r, value: '' });
								resolve(true);
								break;
							}
						}
						if (!found) {
							//console.log(!found);
							this.kioskService.pullCustomLookupsFromPublic(this.paramMap, key[1]).then(val => {
								options = val as any;
								if (val != null) {
									this.keyList.push({ key: key[1], options: options });
									this.questions.push({ type: type, label: label, key: key, options: options, required: r, value: '' });
									resolve(true);
								} else {
									this.questions.push({ type: type, label: label, key: key, options: [{ id: '0', code: 'blank', name: 'Blank', sortOrder: 0 }], required: r, value: '' });
									resolve(true);
								}
							});
						}
					} else {
						//console.log('pulling cq from store');
						this.storage.get(key[1])
							.then((val) => {
								if (val != null) {
									options = val;
									this.questions.push({ type: type, label: label, key: key, options: options, required: r, value: '' });
									resolve(true);
								} else {
									this.questions.push({ type: type, label: label, key: key, options: [{ id: '0', code: 'blank', name: 'Blank', sortOrder: 0 }], required: r, value: '' });
									resolve(true);
								}
							});
					}

				} else if(label.match(/#date#/)) {
					type = 'date';
					label = label.replace(/#date#/, '');
					this.questions.push({ type: type, label: label, key: key, options: options, required: r, value: '' });
					resolve(true);
        }else {
					type = 'text';
					this.questions.push({ type: type, label: label, key: key, options: options, required: r, value: '' });
					resolve(true);
				}
			}
		})
	}

	gotoPreviousSection() {
		if (this.currentGuest.showAdult) {
			this.currentGuest.showAdult = false;
			this.currentGuest.showInitials = true;
		} else if (this.currentGuest.showMinor) {
			this.currentGuest.showMinor = false;
			this.currentGuest.showAdult = true;
		} else if (this.currentGuest.showQuestions) {
			this.currentGuest.showQuestions = false;
			if (this.signedDoc.minors.length > 0) {
				this.currentGuest.showMinor = true;
			} else {
				this.currentGuest.showAdult = true;
			}
		} else if (this.currentGuest.showConsent) {
			this.currentGuest.showConsent = false;
			if (!this.agreeToTerms) {
				this.currentGuest.consent = false;
			}
			if (this.questions.length > 0) {
				this.currentGuest.showQuestions = true;
			} else if (this.signedDoc.minors.length > 0) {
				this.currentGuest.showMinor = true;
			} else {
				this.currentGuest.showAdult = true;
			}
		} else if (this.currentGuest.showSign) {
			if (!this.signedDoc.signature) {
				this.currentGuest.signed = false;
			}
			this.currentGuest.showSign = false;
			this.currentGuest.showConsent = true;

		} else if (this.currentGuest.showSubmit) {
			this.currentGuest.showSubmit = false;
			this.currentGuest.showSign = true;
		}
		//document.getElementById('waiverEntry').scrollIntoView();
	}

	resetInfo(){

		this.currentGuest.showConsent = false;
		this.currentGuest.consent = false;
		this.agreeToTerms = false;
		this.currentGuest.showSign = false;
		this.currentGuest.showSubmit = false;
		this.currentGuest.signed = false;
		this.signedDoc.signature = null;
		for (let i = 0; i < this.signedDoc.minors.length; i++) {
			this.signedDoc.minors[i].signature = null;
		}
		if(this.statusTabs){
			this.statusTabs.updateCurrentTab("Add Info");
		}
	}

	useGuardianAddressMinor(i){
		this.signedDoc.minors[i].addressLine1 = this.signedDoc.address;
		this.signedDoc.minors[i].addressLine2 = this.signedDoc.address2;
		this.signedDoc.minors[i].city = this.signedDoc.city;
		this.signedDoc.minors[i].country = this.signedDoc.country;
		this.signedDoc.minors[i].zip = this.signedDoc.zip;
		this.signedDoc.minors[i].state = this.signedDoc.state;
	}

	ngOnDestroy() {
		console.log("destroyed");
	}

	leaveShowAdult() {

		this.validateAdult();
		if (this.adultInvalid){
			this.currentGuest.adult = false;
			const that = this;
					setTimeout(() => { 
						document.getElementById("info-card").scrollIntoView();
						}, 150);
					return;
		}
		this.validateMinors();
		if (this.minorsInvalid){
			this.currentGuest.adult = false;
			const that = this;
					setTimeout(() => { 
						document.getElementById("minor").scrollIntoView();
						 }, 150);
					return;
		}
		this.validateQuestions();
		if (this.questionsInvalid){
			this.currentGuest.adult = false;
			const that = this;
					setTimeout(() => { 
						document.getElementById("questions").scrollIntoView();
					 }, 150);
					return;
		}

		if (this.validForm) {
			this.currentGuest.adult = true;
			//this.currentGuest.showAdult = false;
			if (this.signedDoc.minors.length > 0) {
				//this.currentGuest.showMinor = true;
				this.signedDoc.currentCountry = this.signedDoc.country;
				if (this.waiverDef.minorsOnly && this.minorCount === 1 && !this.prevData) {
					console.log('LeaveShowAdult-MinorOnly');
					//this.deleteMinor(0);
					this.signedDoc.currentCountry = this.signedDoc.country;
					//this.signedDoc.addMinor();
					//this.minorCount = 1;
					//this.currentMinor = 0;
					//this.minorCountChange();
					console.log(this.signedDoc);
				}
			} 
			this.currentGuest.showConsent = true;
			this.statusTabs.updateCurrentTab("Consent");
			const that = this;
					setTimeout(() => { that.scrollTo('#consent-card'); }, 150);
			
		} else {
			this.currentGuest.adult = false;
			const that = this;
					setTimeout(() => { that.scrollTo('#info-card'); }, 150);
		}
		//document.getElementById('waiverEntry').scrollIntoView();

	}

	leaveShowMinors() {
		this.validateMinors();
		if (this.validForm) {
			this.currentGuest.minors = true;
			this.currentGuest.showMinor = false;

			if (this.questions.length > 0) {
				this.currentGuest.showQuestions = true;
			} else {
				this.currentGuest.showConsent = true;
			}
		} else {
			this.currentGuest.minors = false;
		}
		//document.getElementById('waiverEntry').scrollIntoView();

	}

	leaveShowQuestions() {
		this.validateQuestions();
		if (this.validForm) {
			this.currentGuest.questions = true;
			this.currentGuest.showQuestions = false;
			this.currentGuest.showConsent = true;
		}
		//document.getElementById('waiverEntry').scrollIntoView();

	}

	leaveShowConsent() {
		if (this.agreeToTerms) {
			this.currentGuest.consent = true;
			//this.currentGuest.showConsent = false;
			this.currentGuest.showSign = true;
		}
		//document.getElementById('waiverEntry').scrollIntoView();

	}

	leaveShowSign() {
		/*this.validateSignatures();
		if (this.validForm) {
			this.currentGuest.signed = true;
			this.currentGuest.showSign = false;
			this.currentGuest.showSubmit = true;
		}*/
		//document.getElementById('waiverEntry').scrollIntoView();
	}

	leaveShowSubmit() {
		this.currentGuest.showSubmit = false;
		this.currentGuest.showSign = true;
		//document.getElementById('waiverEntry').scrollIntoView();

	}

	toggleSection(section) {
		//console.log('toggle', section);
		/*if (this.isSectionShown(section)) {
			this.shownSection = null;
		} else {
			this.shownSection = section;
		}
		//this.content.scrollToTop();
		if (section != 1) {
			this.validateForm();
		}*/
	}

	isSectionShown(section) {
		return this.shownSection === section;
	}

	webCam() {
		//console.log(this.videoDevices);
		if (this.videoDevices) {
			this.webcam.getBase64()
				.then(base => {
					this.base64 = base;
					//console.log(this.base64);
					//this.signedDoc.photo = this.base64;
					this.signedDoc.photo = 'data:image/jpeg;base64,' + this.base64.substring(22);
					//question.binaryValue = this.base64.substring(22)
					//question.timeEntered = new Date().toISOString();
					//console.log(question.binaryValue);
				})
				.catch(e => console.error(e))
		}

	}

	onCamError(err) { }

	onCamSuccess() { }

	async takePhoto() {
		/*let alert = await this.alertCtrl.create({
		  header: this.platform.platforms().toString(),
		  buttons: ['Ok']
		})
		alert.present();*/
		
		/*return new Promise(resolve => {
			this.platform.ready().then(async () => {
				if ((this.platform.is('desktop')) || (this.platform.is('mobileweb'))) {
					if (!this.waiverDef.usePhoto) {
						this.sections.splice(1, 1);
					}
					resolve(true);
				} else {
					this.nativeDevice = true;
					if (this.waiverDef.usePhoto) {
							let alert = await this.alertCtrl.create({
								header: 'Ready to take Photo',
								message: 'This waiver requires a photo',
								buttons: [{
									text: 'Cancel',
									role: 'cancel',
									handler: () => {
										this.navCtrl.pop();
										resolve(false);
									}
								}, {
									text: 'Ok',
									handler: () => {
										this.camera.getPicture(this.cameraOptions).then((imageData => {
											//console.log('handler');
											//console.log(imageData);
											//console.info('handler');
											//console.info(imageData);
											this.signedDoc.photo = "data:image/jpeg;base64," + imageData;
											resolve(true);
										}));
									}
								}]
							})
							alert.present();
					} else {
						this.sections.splice(1, 1);
						resolve(true);
					}
				}
			});
		});*/
	}

	async showStop() {
		if (this.waiverDef.showStop) {
			let modal = await this.modalCtrl.create({ component: StopSignPage, componentProps: { msg: this.waiverDef.stopMessage }, backdropDismiss: false });
			modal.onDidDismiss().then(data => {
				this.signedDoc.sawStop = true;
			});
			modal.present();
		}
	}

	showVideo() {
		return new Promise(resolve => {
			if ((this.waiverDef.instructionalVideo) && ((this.waiverDef.videoMetaData != null) && (this.waiverDef.videoMetaData != '')) && (this.waiverDef.instructionalVideo) == 'true') {
				resolve(this.showVideoPage());
			} else {
				resolve(false);
			}
		});
	}

	async showVideoPage() {
		const modal = await this.modalCtrl.create({ component: VideoPage, componentProps: { videoURL: this.waiverDef.videoMetaData }, backdropDismiss: false });
		modal.onDidDismiss().then(data => {
			this.signedDoc.videoEnd = new Date();
			this.signedDoc.watchedVideo = true;
			return true;
		});
		const alert = await this.alertCtrl.create({
			header: 'Video',
			message: 'You are required to watch this video before completing this waiver.  Press [OK] when ready.',
			buttons: [
				{
					text: 'OK', handler: () => {
						this.signedDoc.videoStart = new Date();
						modal.present();
					}
				}
			],
			backdropDismiss: false
		});
		await alert.present();
	}

	async openInitialsModal() {

		const modal = await this.modalCtrl.create({ component: SignaturePage, componentProps: { title: 'Initials', sig: this.signedDoc.initials }, backdropDismiss: false });
		console.log(this.statusTabs);
		modal.onDidDismiss().then(data => {
			if (data.data.result === 'UPDATED') {
				this.signedDoc.initials = data.data.sig;
				if (this.signedDoc.initials) {
					this.currentGuest.initialed = true;
					//this.currentGuest.showInitials = false;
					this.currentGuest.showAdult = true;
					this.statusTabs.updateCurrentTab("Add Info");
					const that = this;
					setTimeout(() => { that.scrollTo('#info-card'); }, 150);
				//this.guestTypeChange();
				}
			}
		});
		modal.present();
	}

	async openSubInitialsModal(id, label) {

		const modal = await this.modalCtrl.create({ component: SignaturePage, componentProps: { title: label, sig: this.subInitials[id].initials }, backdropDismiss: false });
		modal.onDidDismiss().then((data: any) => {
			console.log(data);
			if (data.data.result === 'UPDATED') {
			this.subInitials[id].initials = data.data.sig;
			//console.log('subInitials');
			//console.log(this.subInitials.length);
			this.subInitialsOK = true;
			for (var i = 0; i < this.subInitials.length; i++) {
				//console.log(this.subInitials[i].initials);
				if (this.subInitials[i].initials == null) {
					this.subInitialsOK = false;
					break;
				}
			}
			if (this.subInitialsOK) {
				//setTimeout(function (self) {
				//	self.content.scrollToBottom();
				//}, 600, this);
			}
		}
			//console.log(this.signedDoc.initials);
		});
		return await modal.present();
	}

	async openSignatureModal() {
		const modal = await this.modalCtrl.create({ component: SignaturePage, componentProps: { title: 'Signature', sig: this.signedDoc.signature }, backdropDismiss: false });
		modal.onDidDismiss().then(data => {
			if (data.data.result === 'UPDATED') {
				this.signedDoc.signature = data.data.sig;
				if(this.validateSignatures()){
					this.statusTabs.updateCurrentTab("Submit");
					this.currentGuest.showSubmit = true;
					const that = this;
					setTimeout(() => { that.scrollTo('#submit-card'); }, 150);
				}
			}
		});
		modal.present();
	}

	async openMinorsSignatureModal(index) {
		let modal = await this.modalCtrl.create({
			component: SignaturePage, componentProps: {
				title: 'Minor Signature',
				sig: this.signedDoc.minors[index].signature
			}, backdropDismiss: false
		});
		modal.onDidDismiss().then(data => {
			if (data.data.result === 'UPDATED') {
				this.signedDoc.minors[index].signature = data.data.sig;
				if(this.validateSignatures()){
					this.statusTabs.updateCurrentTab("Submit");
					this.currentGuest.showSubmit = true;
					const that = this;
					setTimeout(() => { that.scrollTo('#submit-card'); }, 150);
				}
			}
		});
		modal.present();
	}

	guestTypeChange() {
		console.log('guestTypeChange', this.guestType)
		this.resetInfo();
		const that = this;
		setTimeout(() => { that.scrollTo('#info-form-card'); }, 150);
		if (this.guestType == 1) {
			this.signedDoc.minors = [];
			this.currentGuest.showMinor = false;
		} else {
			if (this.signedDoc.minors.length == 0) {
				this.currentGuest.showMinor = true;
				//console.log("getTypeChange: addMinor");
				this.signedDoc.currentCountry = this.signedDoc.country;
				this.signedDoc.addMinor();
				this.currentMinor = 0;
			}
		}
	}

	keypress() {
		//console.log('KeyPress this.minorCount: ', this.minorCount)
		if (this.minorCount > 0) {
			this.minorCountChange();
		}
	}

	calcAge() {
		this.signedDoc.calcAge()
		//console.log(this.signedDoc.dateOfBirth)
	}

	calcMinorAge(minorId) {
		if (this.signedDoc.minors[minorId].dateOfBirth === null || this.signedDoc.minors[minorId].dateOfBirth === "") {
			this.signedDoc.minors[minorId].age = null;
			return;
		}

		this.signedDoc.minors[minorId].age = this.signedDoc.getAge(this.signedDoc.minors[minorId].dateOfBirth)
		if (this.signedDoc.minors[minorId].age < 0) {
			this.signedDoc.minors[minorId].age = null;
			this.signedDoc.minors[minorId].dateOfBirth = null;
		}
	}

	minorCountChange() {
		console.log('Blur this.minorCount: ', this.minorCount)
		if (this.minorCount > 0 && this.minorCount <= this.waiverDef.maxMinors) {
			this.signedDoc.minorCountChange(this.minorCount);
		}
	}

	deleteMinor(index) {
		this.currentMinor = null;
		this.signedDoc.minors.splice(index, 1);
		this.minorCount--;
	}

	editMinor(index) {

		this.currentMinor = index;
		console.log("editMinor");

		//console.log(this.currentMinor, this.signedDoc.minors)
		/*
		this.navCtrl.push(MinorPage, {
			minor: this.signedDoc.minors[index],
			waiverDef: this.waiverDef,
			statesData: this.statesData,
			countriesData: this.countriesData,
			signedDoc: this.signedDoc
		});
		*/

	}

	clearValidMessage() {
		this.validMessage = '';
	}

	validateAdult() {
		this.validMessage = '';
		this.validForm = false;
		this.adultInvalid = true;
		if (this.waiverDef.collectGender && (this.waiverDef.requireGender === true) && (!!this.signedDoc.gender === false)) {
			this.validMessage += '<p class="required">Gender</p>';
		}
		if ((this.waiverDef.requireFirstName === true) && (this.signedDoc.firstName.length < 1)) {
			this.validMessage += '<p class="required">First Name</p>';
		}
		if ((this.waiverDef.requireLastName === true) && (this.signedDoc.lastName.length < 1)) {
			this.validMessage += '<p class="required">Last Name</p>';
		}
		if (this.waiverDef.collectEmail && (this.waiverDef.requireEmail === true) && (this.signedDoc.email.length < 1)) {
			this.validMessage += '<p class="required">Email</p>';
		}
		if (this.waiverDef.collectPhone && (this.waiverDef.requirePhone === true) && (this.signedDoc.phone.length < 1)) {
			this.validMessage += '<p class="required">Phone</p>';
		}

		//console.log(this.signedDoc.dateOfBirth, this.signedDoc.age)
		if (this.waiverDef.collectDob) {
			if (this.waiverDef.requireDob === true && this.signedDoc.dateOfBirth === false) {
				this.validMessage += '<p class="required">Birth Date</p>';
			}
			if (this.waiverDef.requireDob === true && this.signedDoc.dateOfBirth === null) {
				this.validMessage += '<p class="required">Check Birth Date format especially 4 digit year</p>';
			}
			if ((this.signedDoc.dateOfBirth !== false) && this.signedDoc.age > 130) {
				this.validMessage += '<p class="required">Age: Please enter a younger adult age</p>';
			}
		}
		if (this.waiverDef.minAdultAge > 0) {
			if (this.signedDoc.age < this.waiverDef.minAdultAge) {
				this.validMessage += '<p class="required">Age: Not an Adult Age</p>';
			}
		}

		if (this.waiverDef.collectAddress && (this.waiverDef.requireAddr1 === true) && (this.signedDoc.address.length < 1)) {
			this.validMessage += '<p class="required">Address</p>';
		}
		if (this.waiverDef.collectAddress && (this.waiverDef.requireAddr2 === true) && (this.signedDoc.address2.length < 1)) {
			this.validMessage += '<p class="required">Address 2</p>';
		}
		if (this.waiverDef.collectAddress && (this.waiverDef.requireCity === true) && (this.signedDoc.city.length < 1)) {
			this.validMessage += '<p class="required">City</p>';
		}
		if (this.waiverDef.collectAddress && (this.waiverDef.requireState === true) && (!!this.signedDoc.state === false)) {
			this.validMessage += '<p class="required">State</p>';
		}
		if (this.waiverDef.collectAddress && (this.waiverDef.requireZip === true) && (this.signedDoc.zip.length < 1)) {
			this.validMessage += '<p class="required">Zip</p>';
		}


		if (this.validMessage === '') {
			this.validForm = true;
			this.adultInvalid = false;
		}

	}

	validateMinors() {
		this.validMessage = '';
		this.validForm = false;
		this.minorsInvalid = true;

		this.signedDoc.minors.forEach(minor => {
			//console.log(minor.dateOfBirth)
			//console.log(!!minor.dateOfBirth)
			//console.log(this.waiverDef.requireDob, minor.dateOfBirth === null)
			let prefix = '<p class="required">' + minor.firstName + ' ' + minor.lastName + ' - ';

			if (minor.firstName === '' || minor.lastName === '') {
				prefix = 'Minor - ';
			}
			console.log('prefix: ', prefix, minor.firstName, minor.lastName)

			if (this.waiverDef.collectDob && (this.waiverDef.requireDob === true) && (minor.dateOfBirth === null)) {
				this.validMessage += '<p class="required">Check Birth Date format especially 4 digit year</p>';
				this.validMessage += prefix + 'Birth Date</p>';
			}
			if (this.waiverDef.minAdultAge > 0) {
				if (minor.dateOfBirth === null) {
					if (! this.validMessage.includes('Birth Date')) {
						this.validMessage += prefix + 'Birth Date</p>';
					}
				}
				if (!this.waiverDef.incapacitated && ((minor.age === null) || (minor.age >= this.waiverDef.minAdultAge))) {
					if (! this.validMessage.includes('4 digit year')) {
						this.validMessage += '<p class="required">Check Birth Date format especially 4 digit year</p>';
					}
					this.validMessage += prefix + 'Age: Not a Minor: Age</p>';
				}
			}

			if (this.waiverDef.collectGender && (this.waiverDef.requireGender === true) && (!!minor === false)) {
				this.validMessage += prefix + 'Gender</p>';
			}
			if ((this.waiverDef.requireFirstName === true) && (minor.firstName.length < 1)) {
				this.validMessage += prefix + 'First Name</p>';
			}
			if ((this.waiverDef.requireLastName === true) && (minor.lastName.length < 1)) {
				this.validMessage += prefix + 'Last Name</p>';
			}
			if (this.waiverDef.collectAddress && (this.waiverDef.requireAddr1 === true) && (minor.addressLine1.length < 1)) {
				this.validMessage += prefix + 'Address</p>';
			}
			if (this.waiverDef.collectAddress && (this.waiverDef.requireAddr2 === true) && (minor.addressLine2.length < 1)) {
				this.validMessage += prefix + 'Address 2</p>';
			}
			if (this.waiverDef.collectAddress && (this.waiverDef.requireCity === true) && (minor.city.length < 1)) {
				this.validMessage += prefix + 'City</p>';
			}
			if (this.waiverDef.collectAddress && (this.waiverDef.requireState === true) && (!!minor.state === false)) {
				this.validMessage += prefix + 'State</p>';
			}
			if (this.waiverDef.collectAddress && (this.waiverDef.requireZip === true) && (minor.zip.length < 1)) {
				this.validMessage += prefix + 'Zip</p>';
			}
			//console.log(this.waiverDef.requireMinorSkiRental, minor)
			if (this.waiverDef.collectMinorSkiRental && (this.waiverDef.requireMinorSkiRental) && ((minor.heightFt === null) || (minor.heightIn === null))) {
				this.validMessage += prefix + 'Height</p>';
			}
			if (this.waiverDef.collectMinorSkiRental && (this.waiverDef.requireMinorSkiRental) && ((minor.heightFt === '') || (minor.heightIn === ''))) {
				this.validMessage += prefix + 'Height</p>';
			}
			if (this.waiverDef.collectMinorSkiRental && (this.waiverDef.requireMinorSkiRental) && (minor.weight === null) || (minor.weight === '')) {
				this.validMessage += prefix + 'Weight</p>';
			}
			if (this.waiverDef.collectMinorSkiRental && (this.waiverDef.requireMinorSkiRental) && (minor.shoeSize === null) || (minor.shoeSize === '')) {
				this.validMessage += prefix + 'Shoe Size</p>';
			}
			/*if ((this.waiverDef.requireMinorSkiRental) && (minor.stance === null))  {
				this.validMessage += prefix + 'Stance</p>';
			}*/
			if (this.waiverDef.collectMinorSkiRental && (this.waiverDef.requireMinorSkiRental) && (minor.ability === null)) {
				this.validMessage += prefix + 'Ability</p>';
			}
			/*if ((this.waiverDef.requireMinorSkiRental) && (minor.conditions === null) || (minor.conditions === '')) {
				this.validMessage += prefix + 'Medical Conditions</p>';
			}*/

		})

		if (this.validMessage === '') {
			this.validForm = true;
			this.minorsInvalid = false;
		}
	}

	validateQuestions() {
		this.validMessage = '';
		this.validForm = false;
		this.questionsInvalid = true;

		for (let i = 0; i < this.questions.length; i++) {
			if ((this.questions[i].required) && ((!this.questions[i].value) || (this.questions[i].length < 1))) {
				this.validMessage += '<p class="required">Questions: ' + this.questions[i].label + '</p>';
			}
		}

		if (this.validMessage === '') {
			this.validForm = true;
			this.questionsInvalid = false;
		}

	}

	validateSignatures() {
		//this.validForm = false;
		this.validMessage = '';

		for (let i = 0; i < this.signedDoc.minors.length; i++) {
			console.log('Minor Sig:', this.signedDoc.minors[i])
			if ((this.waiverDef.requireMinorSig) && (this.signedDoc.minors[i].signature === null)) {
				if (this.signedDoc.minors[i].age >= this.waiverDef.minMinorSigAge) {
					this.validMessage += '<p class="required">' + 'Minor Signature</p>'
				}
			}
		}

		if (!this.signedDoc.signature) {
			this.validMessage += '<p class="required">' + 'Signature</p>'
		}


		if (this.validMessage === '') {
			return true;
		}else {
			return false;
		}
	}

	clickConsent() {
		this.agreeToTerms=!this.agreeToTerms;
		if(this.agreeToTerms){
			this.currentGuest.showSign = true;
			this.currentGuest.consent = true;
			this.statusTabs.updateCurrentTab("Sign");
					const that = this;
					setTimeout(() => { that.scrollTo('#sign-card'); }, 150);
		}else{
			this.currentGuest.showSign = false;
			this.currentGuest.showSubmit = false;
			this.signedDoc.signature = null;
			for (let i = 0; i < this.signedDoc.minors.length; i++) {
				this.signedDoc.minors[i].signature = null;
			}
			this.statusTabs.updateCurrentTab("Consent");
					const that = this;
					setTimeout(() => { that.scrollTo('#consent-card'); }, 150);
		}
	}

	async submitWaiver() {
		// populuate and transform signedDoc object
		this.signedDoc.documentId = this.waiverDef.id;

		this.signedDoc.currentCountry = this.signedDoc.country;
		//console.log(this.currentCountry);
		var isGuardian = false;
		if (this.guestType == 3) {
			isGuardian = true;
		}

		const loading = await this.loadingCtrl.create({
			message: 'Saving Waiver...',
			backdropDismiss: false
		});
		await loading.present();
		this.signedDoc.submit(isGuardian, this.questions, this.subInitials, this.waiverDef).then(() => {
			//console.log('pop callback');
			this.statusTabs.tabsFinished();
			loading.dismiss();
			if (this.bundle) {
				this.events.publish('BundleSigned', this.signedDoc.buildJSONData(this.subInitials));
			} else {	
				this.events.publish('WaiverJustSigned', {});
				this.events.publish('WaiverSigned', this.signedDoc.buildJSONData(this.subInitials));
			}
			console.log(this.eventUid);
			console.log(this.waiverDef);

			if(this.loadedFromLink && !this.eventUid){
				this.navCtrl.navigateBack(['/complete']);
			}else if(this.loadedFromLink && this.eventUid){
				let navigationExtras: NavigationExtras = {
					queryParams: { "a": location.search.substr(3)}
				}
				this.navCtrl.navigateBack(['/event'], navigationExtras);
			}else{
				this.navCtrl.navigateBack(['/waivers']);
			}
		});
	}

	async employeeVerify() {
		//* Employee Verification check
		if (this.waiverDef.useEmployee) {
			const alert = await this.alertCtrl.create({
				header: 'Staff Verification Required',
				message: this.waiverDef.employeeVerifyText,
				backdropDismiss: false,
				buttons: [
					{
						text: 'OK', handler: () => {
							this.finished = true;
						}
					}
				]
			});
			alert.present();
		}
	}

	async validatePin() {
		let pinMatch = false;
		for (var i = 0; i < this.waiverDef.groupMap.length; i++) {
			//console.log('signedDoc.employee', this.signedDoc.employee);
			if (this.waiverDef.groupMap[i].name == this.signedDoc.employee) {
				if (this.waiverDef.groupMap[i].pin == this.signedDoc.pin) {
					pinMatch = true;
					break;
				}
			}
		}

		if (!pinMatch) {
			const alert = await this.alertCtrl.create({
				header: 'PIN Error',
				message: 'Try a valid PIN',
				backdropDismiss: false,
				buttons: [
					{
						text: 'OK', handler: () => {
							this.signedDoc.pin = '';
						}
					}
				]
			});
			alert.present();
		} else {
			//console.log('signedDoc.employee', this.signedDoc.employee);
			//console.log('signedDoc', this.signedDoc);
			this.submitWaiver();
		}
	}
	scrollTo(elementId: string) {
		const element: HTMLElement = document.querySelector(elementId);
		if (element) {
			const y = element.offsetTop;
			console.log(y);
			this.content.scrollToPoint(0, y, 500);
		}
	}
}
