import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minor',
  templateUrl: './minor.page.html',
  styleUrls: ['./minor.page.scss'],
})
export class MinorPage implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
