import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { LoadingController } from '@ionic/angular';

/*
  Generated class for the EmailServiceProvider provider.

  See https://angular.io/guide/dependency-injection for more info on providers
  and Angular DI.
*/

@Injectable({
  providedIn: 'root'
})

export class EmailService {
	//URL = 'https://g1z79aogi0.execute-api.us-east-1.amazonaws.com/dev/'; 
	//URL = 'https://essegf9yil.execute-api.us-east-1.amazonaws.com/dev/';
	//URL = 'https://13w6t0qye5.execute-api.us-east-1.amazonaws.com/dev/';
	//https://13w6t0qye5.execute-api.us-east-1.amazonaws.com/dev/
	//URL = 'http://localhost:4000/';
	devURL = 'https://mail.testing.1riskint.com/';
	prodURL = 'https://mail.1riskint.com/';

  constructor(public httpClient: HttpClient, public loadingCtrl: LoadingController) {
    console.log('Hello EmailServiceProvider Provider');
  }

	sendEmail(payload) {
		let url = this.devURL;

		if(window.location.hostname.toLowerCase().includes("my")){
		  url = this.prodURL;
		}
		this.httpClient.post(url + 'sendwaiver', payload)
			.subscribe(data => {}, error => {});
	}

	sendBarcode(payload) {

		let url = this.devURL;

    	if(window.location.hostname.toLowerCase().includes("my.")){
      		url = this.prodURL;
    	}
		this.httpClient.post(url + 'sendbarcode', payload)
			.subscribe(data => {}, error => {});
	}


	downloadPDF(payload) {
		//let loader = this.loadingCtrl.create({ content: 'Loading...' });
		//loader.present();
		let url = this.devURL;

    	if(window.location.hostname.toLowerCase().includes("my.")){
      		url = this.prodURL;
    	}
		this.httpClient.post(url + 'downloadwaiver', payload)
			.subscribe(data => {
				let d = data as any;
			//	loader.dismiss();
				window.open(d.pdf.pdf);
			});
	} 

	htmlWaiver(payload) {
		//let loader = this.loadingCtrl.create({ content: 'Loading...' });
		//loader.present();

		let url = this.devURL;

    	if(window.location.hostname.toLowerCase().includes("my.")){
      		url = this.prodURL;
    	}
		this.httpClient.post(url + 'htmlwaiver', payload)
			.subscribe(data => {
				//loader.dismiss();
		})
	}

}
