import { Injectable } from '@angular/core';

import { LoadingController, AlertController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { EmailService } from './email.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class KioskService {

  waivers = [];
  waiverDocs = [];
  bundles = [];
  bundleDocs = [];
  localWaivers = [];
  globalLogo = null;

  consent = 'By checking here, you are consenting to the use of your electronic signature in lieu of an original signature ' +
    'on paper. You have the right to request that you sign a paper copy instead. By checking here, you are waiving ' +
    'that right. After consent, you may, upon written request to us, obtain a paper copy of an electronic record. ' +
    'No fee will be charged for such copy and no special hardware or software is required to view it. Your agreement ' +
    'to use an electronic signature with us for any documents will continue until such time as you notify us in ' +
    'writing that you no longer wish to use an electronic signature. There is no penalty for withdrawing your ' +
    'consent. You should always make sure that we have a current email address in order to contact you regarding ' +
    'any changes, if necessary.';

  xkeys = {
    apiName: 'testapi',
    apiKey: '726c0b5a1700412dbfab653647078849',
    unlock: '1riskllc',
    url: 'https://dev.1risk.info/'
  };
  keys = {
    apiName: '',
    apiKey: '',
    unlock: '1risk',
    url: 'https://my.1risk.net/'
  };
  spinnerOptions = {
    spinner: 'bubbles',
    message: 'Loading Data...'
  };
  paramMap = null;

  constructor(public storage: Storage, public loadingCtrl: LoadingController, public alertCtrl: AlertController, public emailService: EmailService, public httpClient: HttpClient) {
    this.storage.get('localWaivers').then((val) => {
      this.localWaivers = val;
    });
  }

  loadKeys() {
    return new Promise(resolve => {
      this.storage.get('keys')
        .then((val) => {
          if (val == null) {
            val = this.keys;
          } else {
            this.keys = val;
          }
          resolve(val as any);
        });
    });
  }

  loadBundleDocs() {
    return new Promise(resolve => {
      this.storage.get('bundleDocs').then(val => {
        resolve(val as any);
      })
    })
  }

  loadWaiverDocs() {
    return new Promise(resolve => {
      //console.log('loadWaiverDocs')
      this.storage.get('waiverDocs')
        .then(val => {
          //console.log('loadWaiverDocs Val', val)
          if (val != null) {
            val.sort(function (a, b) {
              var nameA = a.name.toUpperCase();
              var nameB = b.name.toUpperCase();
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
          }
          //console.log('resolving')
          resolve(val as any);
        });

    });
  }

  load() {
    return new Promise(resolve => {
      this.storage.get('waivers')
        .then((val) => {
          if (val == null) {
            val = this.waivers;
          } else {
            this.waivers = val;
          }
          resolve(val as any);
        });
    });
  }

  loadBundles() {
    return new Promise(resolve => {
      this.storage.get('bundles')
        .then((val) => {
          if (val == null) {
            val = this.bundles;
          } else {
            this.bundles = val;
          }
          resolve(val as any);
        });
    });
  }

  loadActiveBundlesList(k) {
    return new Promise(resolve => {
      if (k.apiName == 'testapi') {
        k.url = 'https://dev.1risk.info/';
      } else if (k.apiName == 'telluridelabapi') {
        k.url = 'https://dev.1risk.info/';
      } else {
        k.url = 'https://my.1risk.net/';
      }

      if (k.apiName == 'billapi') {
        k.url = 'http://localhost:8080/';
      }

      this.keys = k;
      this.storage.set('keys', this.keys);

      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('apikey', this.keys.apiKey);
      var options = { headers: headers, withCredentials: false };

      var url = this.keys.url + this.keys.apiName + '/api/rsg/waiverBundle/activeBundle';
      //console.log('url', url);

      this.httpClient.get(url, options)
        .subscribe((data: any) => {
          this.bundles = data.data;
          resolve(this.bundles);
        }, err => {
          console.log(err);
          this.displayCustomError('Cannot Connect to API');
          resolve(null);
        });
    });

  }
  async loadActiveWaiverList(k) {
    let loader = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'Loading Data...'
    });
    loader.present();
    return new Promise(resolve => {

      if (k.apiName == 'testapi') {
        k.url = 'https://dev.1risk.info/';
      } else if (k.apiName == 'telluridelabapi') {
        k.url = 'https://dev.1risk.info/';
      } else {
        k.url = 'https://my.1risk.net/';
      }

      if (k.apiName == 'billapi') {
        k.url = 'http://localhost:8080/';
      }
      console.log(k);
      this.keys = k;
      this.storage.set('keys', this.keys);

      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('apikey', this.keys.apiKey);
      var options = { headers: headers, withCredentials: false };

      var url = this.keys.url + this.keys.apiName + '/api/v1/wis/getWaivers';
      //console.log('url', url);

      this.httpClient.get(url, options)
        .subscribe((data: any) => {
          this.waivers = data.data;
          console.log(this.waivers);
          loader.dismiss();
          resolve(this.waivers);
        }, err => {
          loader.dismiss();
          console.log(err);
          this.displayCustomError('Cannot Connect to API');
          resolve(null);
        });
    });
  }

  lockIn() {
    return new Promise(async resolve => {
      this.storage.remove('logo');
      this.waiverDocs = [];
      this.storage.set('waivers', this.waivers);
      this.storage.set('bundles', this.bundles);
      this.storage.remove('waiverDocs');


      //this.getlogo();

      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('apikey', this.keys.apiKey);
      var options = { headers: headers, withCredentials: false };
      let totalBundleWaivers = 0;

      this.bundleDocs = [] as any;
      this.storage.remove('bundleDocs');
      for (let i = 0; i < this.bundles.length; i++) {
        if (this.bundles[i].selected) {
          this.bundles[i].waiverDocs = [] as any;
          this.bundleDocs.push(this.bundles[i]);
        }
      }
      for (let i = 0; i < this.bundleDocs.length; i++) {
        for (let y = 0; y < this.bundleDocs[i].waiverBundles.length; y++) {
          totalBundleWaivers++;

          let url = this.keys.url + this.keys.apiName + '/api/v1/rsg/document/' + this.bundleDocs[i].waiverBundles[y].document;

          this.httpClient.get(url, options)
            .subscribe((data: any) => {
              if (data.data) {
                if ((data.data.electronicSignatureConsent == null) || (data.data.electronicSignatureConsent == '')) {
                  data.data.electronicSignatureConsent = this.consent;
                }
                data.data.sortOrder = this.bundleDocs[i].waiverBundles[y].sortOrder;
                this.bundleDocs[i].waiverDocs.push(data.data);

                this.buildQuestions(data.data.customFields[0].f1);
                this.buildQuestions(data.data.customFields[0].f2);
                this.buildQuestions(data.data.customFields[0].f3);
                this.buildQuestions(data.data.customFields[0].f4);
                this.buildQuestions(data.data.customFields[0].f5);
                this.buildQuestions(data.data.customFields[0].f6);
                this.buildQuestions(data.data.customFields[0].f7);
                this.buildQuestions(data.data.customFields[0].f8);
                this.buildQuestions(data.data.customFields[0].f9);
                this.buildQuestions(data.data.customFields[0].f10);

                this.bundleDocs[i].waiverDocs.sort(function (a, b) {
                  console.log('sort', a, b);
                  a = a.sortOrder + ';' + a.name.toUpperCase();
                  b = b.sortOrder + ';' + b.name.toUpperCase();
                  var ax = [], bx = [];
                  a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
                  b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
                  while (ax.length && bx.length) {
                    var an = ax.shift();
                    var bn = bx.shift();
                    var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
                    if (nn) return nn;
                  }
                  return ax.length - bx.length;
                });

                //console.log(this.bundleDocs[i].waiverDocs);

                this.storage.set('bundleDocs', this.bundleDocs);
              }
            });
        }
      }


      for (var i = 0; i < this.waivers.length; i++) {
        if (this.waivers[i].selected) {

          let url = this.keys.url + this.keys.apiName + '/api/v1/rsg/document/' + this.waivers[i].waiverId;

          this.httpClient.get(url, options)
            .subscribe((data: any) => {
              if ((data.data.electronicSignatureConsent == null) || (data.data.electronicSignatureConsent == '')) {
                data.data.electronicSignatureConsent = this.consent;
              }
              this.waiverDocs.push(data.data);
              this.buildQuestions(data.data.customFields[0].f1);
              this.buildQuestions(data.data.customFields[0].f2);
              this.buildQuestions(data.data.customFields[0].f3);
              this.buildQuestions(data.data.customFields[0].f4);
              this.buildQuestions(data.data.customFields[0].f5);
              this.buildQuestions(data.data.customFields[0].f6);
              this.buildQuestions(data.data.customFields[0].f7);
              this.buildQuestions(data.data.customFields[0].f8);
              this.buildQuestions(data.data.customFields[0].f9);
              this.buildQuestions(data.data.customFields[0].f10);

              console.log(this.waiverDocs);
              this.storage.set('waiverDocs', this.waiverDocs);
            });
        }
      }

      let len = this.waiverDocs.length + totalBundleWaivers + 1;

      setTimeout(() => {
        console.log(len);
        resolve(true);
      }, 2000 * len);
    });
  }


  buildQuestions(val) {
    if ((val != null) && (val != '')) {
      if (val.match(/{(.*)}/) != null) {
        let key = val.match(/{(.*)}/);
        this.pullCustomLookupsFromApi(key[1]);
      }
    }
  }

  pullCustomLookupsFromApi(key) {
    this.storage.remove(key);

    var headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('apikey', this.keys.apiKey);
    var options = { headers: headers, withCredentials: false };

    var url = this.keys.url + this.keys.apiName + '/api/v1/lookup/custom?groupCode=' + key;

    this.httpClient.get(url, options)
      .subscribe((data: any) => {
        let result = data.data.filter(it => {
          return it.active === true;
        })
          .sort(function (a, b) {
            a = a.sortOrder + ';' + a.name.toUpperCase();
            b = b.sortOrder + ';' + b.name.toUpperCase();
            var ax = [], bx = [];
            a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
            b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
            while (ax.length && bx.length) {
              var an = ax.shift();
              var bn = bx.shift();
              var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
              if (nn) return nn;
            }
            return ax.length - bx.length;
          });
        this.storage.set(key, result);
      });
  }

  getlogoforPublic(paramMap) {
    var headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('apikey', paramMap.key);
    var options = { headers: headers, withCredentials: false };

    var url = paramMap.url + '/api/v1/system/logo';
    this.httpClient.get(url, options)
      .subscribe((data: any) => {
        this.globalLogo = 'data:image/jpeg;base64,' + data.data;
      });
  }



  getlogo() {
    this.globalLogo = this.storage.get('logo').then((val) => {
      if (val) {
        this.globalLogo = val;
        // console.log('logo in storage', this.globalLogo);
      } else {
        var headers = new HttpHeaders();
        headers = headers.append('Content-Type', 'application/json');
        headers = headers.append('apikey', this.keys.apiKey);
        var options = { headers: headers, withCredentials: false };

        console.log(this.keys)
        var url = this.keys.url + this.keys.apiName + '/api/v1/system/logo';

        this.httpClient.get(url, options)
          .subscribe((data: any) => {
            this.globalLogo = 'data:image/jpeg;base64,' + data.data;
            this.storage.set('logo', this.globalLogo);
            console.log('logo', this.globalLogo);
          });
      }
    });;
  }

  pullLookupsFromApi(key) {
    this.storage.remove(key);

    var headers = new HttpHeaders();
    headers = headers.append('Content-Type', 'application/json');
    headers = headers.append('apikey', this.keys.apiKey);
    var options = { headers: headers, withCredentials: false };

    var url = this.keys.url + this.keys.apiName + '/api/v1/lookup/' + key;

    this.httpClient.get(url, options)
      .subscribe((data: any) => {
        let result = data.data.filter(it => {
          return it.active === true;
        })
          .sort(function (a, b) {
            a = a.sortOrder + ';' + a.name.toUpperCase();
            b = b.sortOrder + ';' + b.name.toUpperCase();
            var ax = [], bx = [];
            a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
            b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
            while (ax.length && bx.length) {
              var an = ax.shift();
              var bn = bx.shift();
              var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
              if (nn) return nn;
            }
            return ax.length - bx.length;
          });

        this.storage.set(key, result);
      });
  }

  globalStates = [];
  loadLookup(key) {
    return new Promise(resolve => {
      this.storage.get(key)
        .then((val) => {
          if (key === 'states') {
            this.globalStates = val;
          }
          resolve(val as any);
        });
    });
  }

  getStateName(value: any) {
    let val = ''
    if (value) {
      for (let i = 0; i < this.globalStates.length; i++) {
        if (this.globalStates[i].id === value) {
          val = this.globalStates[i].name;
          break;
        }
      }
    }
    return val;
  }


  submitWaiver(waiver, waiverDef) {
    return new Promise(async resolve => {
      let loader = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: 'Saving Waiver...'
      });
      loader.present();

      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('apikey', this.keys.apiKey);
      var options = { headers: headers, withCredentials: false };

      var url = '';
      let sysApiName = '';
      if (this.keys.apiName != '') {
        sysApiName = this.keys.apiName;
        url = this.keys.url + this.keys.apiName + '/api/v1/rsg/signed/update/' + this.keys.apiKey;
      } else {
        sysApiName = this.paramMap.url;
        let pos = sysApiName.lastIndexOf("/");
        sysApiName = sysApiName.substring(pos + 1);
        url = this.paramMap.url + '/api/v1/rsg/signed/update/' + this.paramMap.key;
      }

      //console.log('email test', waiverDef, waiver);
      //return;
      this.httpClient.post(url, waiver, options)
        .subscribe((data: any) => {
          //console.log(data);
          this.backupSave(data.data, sysApiName, options);
          if (waiverDef && waiverDef.emailGuest && waiverDef.emailSubject) {
            console.log('pass waiverdef test')
            if (waiver.email || waiver.guardianEmail) {
              console.log('pass waiver email address test')
              waiver.signedDate = data.data.signedDate;
              waiver.stateName = this.getStateName(waiver.state);
              for (let i = 0; i < waiver.minors.length; i++) {
                waiver.minors[i].stateName = this.getStateName(waiver.minors[i].state)
              }

              let payload = {
                waiverDef: waiverDef,
                signedWaiver: waiver,
                logo: this.globalLogo
              }
              console.log(payload);
              this.emailService.sendEmail(payload);

            }
          }
          if (waiverDef && waiverDef.printBarcode) {
            console.log('pass waiverdef test')
            if (waiver.email || waiver.guardianEmail) {
              console.log('pass waiver email address test')
              waiver.signedDate = data.data.signedDate;
              waiver.stateName = this.getStateName(waiver.state);
              for (let i = 0; i < waiver.minors.length; i++) {
                waiver.minors[i].stateName = this.getStateName(waiver.minors[i].state)
              }

              let payload = {
                waiverDef: waiverDef,
                signedWaiver: waiver,
                logo: this.globalLogo
              }
              console.log(payload);
              this.emailService.sendBarcode(payload);

            }
          }
          loader.dismiss();
          resolve(true);
        }, err => {
          loader.dismiss();
          if (waiver.signedDate == null) {
            waiver.signedDate = new Date();
          }
          this.saveWaiverLocally(waiver).then(val => {
            console.log("local save");
            resolve(false);
          });
        });
    });
  }

  backupSave(waiver, apiName, options) {
    this.httpClient.post('https://staging.1risk.info/wb/waivers', { api: apiName, data: waiver }, options)
      .subscribe((data: any) => {
        //console.log(data);
      });
  }

  uploadLocalWaivers() {
    return new Promise(resolve => {
      this.localWaivers = this.localWaivers || [];
      var tmpWaivers = Object.assign([], this.localWaivers);
      console.log(tmpWaivers);
      console.log(this.localWaivers);
      this.localWaivers = [];
      this.storage.set('localWaivers', this.localWaivers);

      var cnt = tmpWaivers.length;

      for (var i = cnt; i > 0; i--) {
        this.submitWaiver(tmpWaivers[i - 1], null).then(val => {
          if (!val) {
            // still wasn't able to save
            this.storage.get('localWaivers').then(val => {
              this.localWaivers = val;
            });
          }
        });
      }

      setTimeout(() => {
        resolve(true);
      }, cnt * 10);
    });
  }

  getWaiverLocalCount() {
    return new Promise(resolve => {
      this.localWaivers = this.localWaivers || [];
      console.log('this.localWaivers.length');
      console.log(this.localWaivers.length);
      resolve(this.localWaivers.length);
    });
  }

  saveWaiverLocally(w) {
    return new Promise(async resolve => {
      let loader = await this.loadingCtrl.create({
        spinner: 'bubbles',
        message: 'Saving Waiver Locally...'
      });
      loader.present();
      this.localWaivers = this.localWaivers || [];
      this.localWaivers.push(w);
      this.storage.set('localWaivers', this.localWaivers).then((val) => {
        loader.dismiss();
        resolve(true);
      });
    });

  }

  async loadGMWaiverDocsForEvent(paramMap) {
    this.paramMap = paramMap;
    let loader = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'Loading Your Waiver...'
    });
    loader.present();
    paramMap.eventData = []
    return new Promise(resolve => {
      this.loadGMEventData(paramMap).then(val => {
        var data = val as any;
        console.log('Event');
        console.log(data);
        paramMap.eventData.push(data);
        data.waiverID = data.waiver;
        paramMap.waivers.push(data.waiverID); 
        
        this.loadWaiverDocsFromPublic(paramMap).then(val => {
          loader.dismiss();
          resolve(val);
        })
      })
    })
  }

  loadGMEventData(paramMap) {
    return new Promise(resolve => {
      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('apikey', paramMap.key);
      var options = { headers: headers, withCredentials: false };

      let oldUrl = paramMap.url.split('/');
      let apiName = oldUrl[3];


      var url = 'https://api.1riskint.com/' + apiName + '/group-manager-u/find-kiosk/';
      if (window.location.hostname.toLowerCase().includes("testing.")) {
        url = 'https://api.testing.1riskint.com/testapi/group-manager-u/find-kiosk/';
      }
      this.httpClient.post(url, {UUID: paramMap.eventId}, options)
        .subscribe((data: any) => {
          resolve(data);
        });
    });
  }


  async loadWaiverDocsForEvent(paramMap) {
    this.paramMap = paramMap;
    let loader = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'Loading Your Waiver...'
    });
    loader.present();
    paramMap.eventData = []
    return new Promise(resolve => {
      this.loadEventData(paramMap).then(val => {
        var data = val as any;
        console.log('Event');
        console.log(data);
        paramMap.eventData.push(data);
        console.log(data.waiverBundle);
        if (data.waiverBundle != null) {
          console.log(paramMap.eventData);
          console.log(data.associatedWaiverBundle.waiverBundles);
          for (var i = 0; i < data.associatedWaiverBundle.waiverBundles.length; i++) {
            paramMap.waivers.push(data.associatedWaiverBundle.waiverBundles[i].document);
          }
        } else {
          paramMap.waivers.push(data.waiverID);
        }
        this.loadWaiverDocsFromPublic(paramMap).then(val => {
          loader.dismiss();
          resolve(val);
        })
      })
    })
  }

  loadEventData(paramMap) {
    return new Promise(resolve => {
      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('apikey', paramMap.key);
      var options = { headers: headers, withCredentials: false };

      var url = paramMap.url + '/api/rsg/eventManager/findEventByUuid?eventUUID=' + paramMap.eventId;
      this.httpClient.get(url, options)
        .subscribe((data: any) => {
          resolve(data.data);
        });
    });
  }

  loadWaiverDocsFromPublic(paramMap) {
    this.paramMap = paramMap;
    //  let loader = this.loadingCtrl.create({
    //    spinner: 'ios',
    //    content: 'Loading Your Waiver...'
    //  });
    //  loader.present();
    var promises = [];
    return new Promise(resolve => {
      for (var i = 0; i < paramMap.waivers.length; i++) {
        promises.push(this.loadWaiverFromPublic({ index_id: paramMap.waivers[i], url: paramMap.url, key: paramMap.key }));
      }
      Promise.all(promises).then(val => {
        //console.log('promiseall.val');
        //console.log(val);
        var docs = [];
        for (var i = 0; i < val.length; i++) {
          docs.push(val[i]);
        }
        //     loader.dismiss();
        resolve(docs);
      })
    });

  }

  loadWaiverFromPublic(paramMap) {
    return new Promise(resolve => {
      console.log('getting Waiver for: ' + paramMap.index_id);
      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('apikey', paramMap.key);
      var options = { headers: headers, withCredentials: false };

      var url = paramMap.url + '/api/v1/rsg/document/' + paramMap.index_id;
      this.httpClient.get(url, options)
        .subscribe((data: any) => {
          var waiver = data;
          console.log(waiver);
          //console.log('waiverid: ' + waiver.data.id);
          //console.log(waiver);
          if ((waiver.data.electronicSignatureConsent == null) || (waiver.data.electronicSignatureConsent == '')) {
            waiver.data.electronicSignatureConsent = 'By checking here, you are consenting to the use of your electronic signature in lieu of an original signature ' +
              'on paper. You have the right to request that you sign a paper copy instead. By checking here, you are waiving ' +
              'that right. After consent, you may, upon written request to us, obtain a paper copy of an electronic record. ' +
              'No fee will be charged for such copy and no special hardware or software is required to view it. Your agreement ' +
              'to use an electronic signature with us for any documents will continue until such time as you notify us in ' +
              'writing that you no longer wish to use an electronic signature. There is no penalty for withdrawing your ' +
              'consent. You should always make sure that we have a current email address in order to contact you regarding ' +
              'any changes, if necessary.';
          }

          resolve(waiver.data);

					/*
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f1);
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f2);
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f3);
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f4);
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f5);
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f6);
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f7);
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f8);
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f9);
					this.buildQuestionsFromPublic(paramMap, waiver.data.customFields[0].f10);
	
	
					if (waiver.data.collectAddress) {
						this.pullLookupsFromPublic(paramMap, 'states').then(data => {
							this.pullLookupsFromPublic(paramMap, 'countries').then(data => {
								if (waiver.data.collectEci) {
									this.pullLookupsFromPublic(paramMap, 'relationships').then(data => {
										//console.log('dismissAll');
										//loader.dismissAll();
										resolve(waiver.data);
									})
								} else {
									resolve(waiver.data);
								}
							})
						})
					} else if (waiver.data.collectEci) {
						this.pullLookupsFromPublic(paramMap, 'relationships').then(data => {
							//loader.dismissAll();
							resolve(waiver.data);
						})
					} else {
						//loader.dismissAll();
						resolve(waiver.data);
					}*/
        }
          , err => {
            //loader.dismissAll();
            this.displayError(err);
            resolve({});
          });
    });
  }

  async displayCustomError(message) {
    const alert = await this.alertCtrl.create({
      header: 'System Error',
      message: message,
      buttons: ['OK']
    });
    await alert.present();
  }

  async displayError(err) {
    const alert = await this.alertCtrl.create({
      header: 'System Error',
      message: err._body,
      buttons: ['OK']
    });
    await alert.present();
  }

  pullLookupsFromPublic(paramMap, key) {
    //this.storage.remove(key);

    return new Promise(resolve => {
      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('apikey', paramMap.key);
      var options = { headers: headers, withCredentials: false };

      var url = paramMap.url + '/api/v1/lookup/' + key;
      //console.log(key);
      this.httpClient.get(url, options)
        .subscribe((data: any) => {
          //console.log(data.data);
          let result = data.data.filter(it => {
            return it.active === true;
          })
            .sort(function (a, b) {
              a = a.sortOrder + ';' + a.name.toUpperCase();
              b = b.sortOrder + ';' + b.name.toUpperCase();
              var ax = [], bx = [];
              a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
              b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
              while (ax.length && bx.length) {
                var an = ax.shift();
                var bn = bx.shift();
                var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
                if (nn) return nn;
              }
              return ax.length - bx.length;
            });
          //this.storage.set(key, data.data);
          resolve(result);
        }, err => {
          //loader.dismissAll();
          //this.displayError(err);
          resolve([{ id: '0', code: 'blank', name: 'Blank', sortOrder: 0, abbr: 'Blank' }]);
        });
    });
  }

  pullCustomLookupsFromPublic(paramMap, key) {
    this.storage.remove(key);
    return new Promise(resolve => {

      var headers = new HttpHeaders();
      headers = headers.append('Content-Type', 'application/json');
      headers = headers.append('apikey', paramMap.key);
      var options = { headers: headers, withCredentials: false };

      var url = paramMap.url + '/api/v1/lookup/custom?groupCode=' + key;

      this.httpClient.get(url, options)
        .subscribe((data: any) => {
          let result = data.data.filter(it => {
            return it.active === true;
          })
            .sort(function (a, b) {
              a = a.sortOrder + ';' + a.name.toUpperCase();
              b = b.sortOrder + ';' + b.name.toUpperCase();
              var ax = [], bx = [];
              a.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { ax.push([$1 || Infinity, $2 || ""]) });
              b.replace(/(\d+)|(\D+)/g, function (_, $1, $2) { bx.push([$1 || Infinity, $2 || ""]) });
              while (ax.length && bx.length) {
                var an = ax.shift();
                var bn = bx.shift();
                var nn = (an[0] - bn[0]) || an[1].localeCompare(bn[1]);
                if (nn) return nn;
              }
              return ax.length - bx.length;
            });
          if (result.length == 0) {
            result = [{ id: '0', code: 'blank', name: 'Blank', sortOrder: 0, abbr: 'Blank' }];
          }
          resolve(result);
        }, err => {
          //loader.dismissAll();
          //this.displayError(err);
          resolve([{ id: '0', code: 'blank', name: 'Blank', sortOrder: 0, abbr: 'Blank' }]);
        });
    });
  }

  buildQuestionsFromPublic(paramMap, val) {
    if ((val != null) && (val != '')) {
      if (val.match(/{(.*)}/) != null) {
        let key = val.match(/{(.*)}/);
        this.pullCustomLookupsFromPublic(paramMap, key[1]);
      }
    }
  }

}
