import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { Camera } from '@ionic-native/camera/ngx';
import { IonicModule } from '@ionic/angular';
import { StatusTabsComponent } from '../components/status-tabs/status-tabs.component';
import { NgxMaskModule } from 'ngx-mask';

import { SignPageRoutingModule } from './sign-routing.module';
import { SanatizeHtmlPipe } from '../pipes/sanatize-html.pipe';
import { StateCodeFilterPipe } from '../pipes/state-code-filter.pipe';
import { OneriskDatetimeComponent } from '../components/onerisk-datetime/onerisk-datetime.component';

import { SignPage } from './sign.page';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    SignPageRoutingModule,
    NgxMaskModule
  ],
  declarations: [SignPage, SanatizeHtmlPipe, StateCodeFilterPipe, StatusTabsComponent, OneriskDatetimeComponent],
  providers: [Camera],
})
export class SignPageModule {}
