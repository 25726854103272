
import { Injectable } from '@angular/core';
import { KioskService } from './kiosk.service';
import _ from "lodash";
import * as moment from 'moment';

@Injectable({
	providedIn: 'root'
})
export class SignedWaiverService {
	active = false;
	documentName = '';
	documentId = null;
	firstName = '';
	lastName = '';
	name = '';
	email = '';
	address = '';
	address2 = '';
	city = '';
	state = '';
	zip = '';
	phone = '';
	dateOfBirth = null;
	age = null;
	gender = null;
	photo = null;
	guardianName = null;
	guardianFirstName = null;
	guardianLastName = null;
	guardianEmail = null;
	guardianPhone = null;
	guardianDob = null;
	emergencyContact = null;
	emergencyRelationship = null;
	emergencyPhone = null;
	customData = [
		{
			f1: null,
			f2: null,
			f3: null,
			f4: null,
			f5: null,
			f6: null,
			f7: null,
			f8: null,
			f9: null,
			f10: null
		}
	];
	minors = [];
	bodyFinal = null;
	barcode = null;
	embeddedInitials = [null];
	sawStop = null;
	signSource = '1waiver-ion4';
	signature = null;
	signedDate = null;
	signedImg = null;
	initials = null;
	creationDate = null;
	creationUser = null;
	electronicConsent = null;
	notes = '';
	pin = null;
	employee = null;
	videoEnd = null;
	videoStart = null;
	watchedVideo = null;
	eventUid = null;
	country = null;
	currentCountry = null;

	international = false;
	dateFormat = 'MM/DD/YYYY';


	/*
	public signedDoc = {
		active: false,
		documentId: null,
		firstName: '',
		lastName: '',
		name: '',
		email: '',
		address: '',
		address2: '',
		city: '',
		state: '',
		zip: '',
		phone: '',
		dateOfBirth: null,
		age: null,
		gender: null,
		photo: null,
		guardianName: null,
		guardianFirstName: null,
		guardianLastName: null,
		guardianEmail: null,
		guardianPhone: null,
		guardianDob: null,
		emergencyContact: null,
		emergencyRelationship: null,
		emergencyPhone: null,
		customData: [
		  {
			f1: null,
			f2: null,
			f3: null,
			f4: null,
			f5: null,
			f6: null,
			f7: null,
			f8: null,
			f9: null,
			f10: null
		  }
		],
		minors: [],
		bodyFinal: null,
		embeddedInitials: [null],
		sawStop: null,
		signSource: 'ionic',
		signature: null,
		signedDate: null,
		signedImg: null,
		initials: null,
		creationDate: null,
		creationUser: null,
		electronicConsent: null,
		notes: '',
		pin: null,
		videoEnd: null,
		videoStart: null,
		watchedVideo: null
	  };*/
	constructor(public service: KioskService) {
		console.log('Hello SignedWaiverProvider Provider');
		const lang = window.navigator.language.toLowerCase();
		if (lang == "en" || lang == "en-us") {
			this.international = false;
			this.dateFormat = 'MM/DD/YYYY';
		} else {
			this.international = true;
			this.dateFormat = 'DD/MM/YYYY';
		}
	}

	calcAge() {
		console.log('Enter calcAge', this.dateOfBirth)
		if (this.dateOfBirth === null || this.dateOfBirth === "") {
			this.age = null;
			return;
		}
		this.age = this.getAge(this.dateOfBirth);
	}

	getAge(dateValue) {
		const date = moment(dateValue, this.dateFormat)
		let age = null
		if (date.isValid()) {
			let today = new Date();
			age = today.getFullYear() - date.year();
			let m = today.getMonth() - date.month();
			console.log(age, today.getFullYear(), date.year(), m, today.getMonth(), date.month(), today.getDate(), date.date());
			if (m === 0 && (today.getDate() < date.date())) {
				console.log('reduced age', age)
				age--;
			} else if (m < 0) {
				console.log('reduced age', age)
				age--;
			}

		}
		return age;
	}

	addMinor() {
		let minor = {
			firstName: '',
			lastName: '',
			addressLine1: '',
			addressLine2: '',
			city: '',
			state: null,
			zip: '',
			phoneNumber: null,
			dateOfBirth: null, //new Date('01/01/' + new Date().getFullYear()).toISOString(),
			age: null,
			gender: null,
			country: null,
			heightFt: null,
			heightIn: null,
			weight: null,
			shoeSize: null,
			stance: null,
			ability: null,
			conditions: null,
			signature: null
		};

		this.minors.push(minor);
	}

	minorCountChange(cnt) {
		console.log(cnt);
		if (this.minors.length < cnt) {
			for (var i = 0; i < cnt; i++) {
				this.addMinor();
			}
		}
		if (this.minors.length > cnt) {
			this.minors.splice(cnt, this.minors.length - cnt);
		}
	}

	new() {
		this.active = false;
		this.documentName = '';
		this.documentId = null;
		this.firstName = '';
		this.lastName = '';
		this.name = '';
		this.email = '';
		this.address = '';
		this.address2 = '';
		this.city = '';
		this.country = '';
		this.state = '';
		this.zip = '';
		this.phone = '';
		this.dateOfBirth = null, //new Date('01/01/' + new Date().getFullYear()).toISOString();
		this.age = null;
		this.gender = null;
		this.photo = null;
		this.guardianName = null;
		this.guardianFirstName = null;
		this.guardianLastName = null;
		this.guardianEmail = null;
		this.guardianPhone = null;
		this.guardianDob = null;
		this.emergencyContact = null;
		this.emergencyRelationship = null;
		this.emergencyPhone = null;
		this.customData = [
			{
				f1: null,
				f2: null,
				f3: null,
				f4: null,
				f5: null,
				f6: null,
				f7: null,
				f8: null,
				f9: null,
				f10: null
			}
		];
		this.minors = [];
		this.bodyFinal = null;
		this.embeddedInitials = [null];
		this.sawStop = null;
		this.signSource = '1waiver-ion';
		this.signature = null;
		this.signedDate = null;
		this.signedImg = null;
		this.initials = null;
		this.creationDate = null;
		this.creationUser = null;
		this.electronicConsent = null;
		this.notes = '';
		this.pin = null;
		this.employee = null;
		this.videoEnd = null;
		this.videoStart = null;
		this.watchedVideo = null;
		this.eventUid = '';
	}

	submit(isGuardian, questions, initials, waiverDef) {

		if (this.dateOfBirth) {
			this.dateOfBirth = moment(this.dateOfBirth, this.dateFormat).format('YYYY-MM-DD');
		}

		this.electronicConsent = true;
		this.firstName = this.firstName.trim();
		this.lastName = this.lastName.trim();
		for (let i = 0; i < this.minors.length; i++) {
			this.minors[i].firstName = this.minors[i].firstName.trim();
			this.minors[i].lastName = this.minors[i].lastName.trim();

			if (this.minors[i].dateOfBirth) {
				this.minors[i].dateOfBirth = moment(this.minors[i].dateOfBirth, this.dateFormat).format('YYYY-MM-DD');
			}
			
		}

		this.name = this.firstName + ' ' + this.lastName;
		if (isGuardian) {
			this.guardianName = this.name;
			this.name = null;

			this.guardianFirstName = this.firstName;
			this.firstName = null;

			this.guardianLastName = this.lastName;
			this.lastName = null;

			this.guardianEmail = this.email;
			this.email = null;

			this.guardianPhone = this.phone;
			this.phone = null;

			this.guardianDob = this.dateOfBirth
			this.dateOfBirth = null;
		}

		while (questions.length < 10) {
			questions.push({ value: '' });
		}

		//console.log(questions);

		this.customData[0] = {
			f1: questions[0].value,
			f2: questions[1].value,
			f3: questions[2].value,
			f4: questions[3].value,
			f5: questions[4].value,
			f6: questions[5].value,
			f7: questions[6].value,
			f8: questions[7].value,
			f9: questions[8].value,
			f10: questions[9].value
		}

		if (this.photo) {
			this.photo = this.photo.substr(23);
		}

		//this.initials = null;


		var data = this.buildJSONData(initials);
		return new Promise(resolve => {
			this.service.submitWaiver(data, waiverDef).then(() => {
				//this.signature = null;
				resolve(true);
			});
		});
	}

	buildJSONData(initials) {
		var data = {
			active: this.active,
			documentId: this.documentId,
			firstName: this.firstName,
			lastName: this.lastName,
			name: this.name,
			email: this.email,
			address: this.address,
			address2: this.address2,
			city: this.city,
			state: this.state,
			zip: this.zip,
			phone: this.phone,
			dateOfBirth: this.dateOfBirth,
			age: this.age,
			gender: this.gender,
			photo: this.photo,
			guardianName: this.guardianName,
			guardianFirstName: this.guardianFirstName,
			guardianLastName: this.guardianLastName,
			guardianEmail: this.guardianEmail,
			guardianPhone: this.guardianPhone,
			guardianDob: this.guardianDob,
			emergencyContact: this.emergencyContact,
			emergencyRelationship: this.emergencyRelationship,
			emergencyPhone: this.emergencyPhone,
			customData: this.customData,
			minors: _.cloneDeep(this.minors),
			bodyFinal: this.bodyFinal,
			embeddedInitials: [],
			sawStop: this.sawStop,
			signSource: '1waiver-ion4',
			signature: null,
			signedDate: this.signedDate,
			signedImg: this.signedImg,
			initials: null,
			barcode: this.randomBarcode(15),
			creationDate: this.creationDate,
			creationUser: this.creationUser,
			electronicConsent: this.electronicConsent,
			notes: this.notes,
			pin: this.pin,
			employee: this.employee,
			videoEnd: this.videoEnd,
			videoStart: this.videoStart,
			watchedVideo: this.watchedVideo,
			eventUid: this.eventUid
		};


		for (let i = 0; i < this.minors.length; i++) {
			if (this.minors[i].signature) {
				data.minors[i].signature = this.minors[i].signature.substr(22);
			}
		}
		data.signature = this.signature.substr(22);
		
		data.embeddedInitials = [];
		data.embeddedInitials.push({ initialsId: 'ack', initials: this.initials.substr(22) })
		if (initials) {
			for (var i = 0; i < initials.length; i++) {
				data.embeddedInitials.push({ initialsId: i, initials: initials[i].initials.substr(22) })
			}
		}
		console.log(data);

		return data;
	}

	randomBarcode(length) {
		var result           = '';
		var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
		var charactersLength = characters.length;
		for ( var i = 0; i < length; i++ ) {
		  result += characters.charAt(Math.floor(Math.random() * 
	 charactersLength));
	   }
	   return result;
	}

	fillFromPrevData(data) {
		this.firstName = data.firstName;
		this.lastName = data.lastName;
		this.name = data.name;
		this.email = data.email;
		this.address = data.address;
		this.address2 = data.address2;
		this.city = data.city;
		this.state = data.state;
		this.zip = data.zip;
		this.phone = data.phone;
		this.dateOfBirth = data.dateOfBirth;
		this.age = data.age;
		this.gender = data.gender;
		this.photo = data.photo;
		this.guardianName = data.guardianName;
		this.guardianFirstName = data.guardianFirstName;
		this.guardianLastName = data.guardianLastName;
		this.guardianEmail = data.guardianEmail;
		this.guardianPhone = data.guardianPhone;
		this.guardianDob = data.guardianDob;
		this.emergencyContact = data.emergencyContact;
		this.emergencyRelationship = data.emergencyRelationship;
		this.emergencyPhone = data.emergencyPhone;
		this.minors = data.minors;
		this.eventUid = data.eventUid;
		this.country = this.currentCountry;
	}

}
