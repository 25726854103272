import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {CommonModule} from "@angular/common";

@Pipe({
  name: 'sanatizeHtml'
})
export class SanatizeHtmlPipe implements PipeTransform {
  /**
   * Takes a value and makes it lowercase.
   */
  constructor(private _sanitizer: DomSanitizer) {

  }
  transform(value: string): SafeHtml {
    return this._sanitizer.bypassSecurityTrustHtml(value);
  }
}

