import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stateCodeFilter'
})
export class StateCodeFilterPipe implements PipeTransform {

  transform(items: any[], filter: any): any {
    if (!items || !filter) {
      return items;
    }
    return items.filter((item) => item.country === filter);
  }
}
