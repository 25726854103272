import { Component, OnInit } from '@angular/core';
//import { NavController, NavParams, Events } from '@ionic/angular';
import { LoadingController, AlertController } from '@ionic/angular';
import { KioskService } from '../services/kiosk.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
//import { WaiversPage } from '../waivers/waivers';
import { EventsService } from '../services/events.service';
import { SortPipe } from '../pipes/sort.pipe';


@Component({
  selector: 'app-kiosk',
  templateUrl: './kiosk.page.html',
  styleUrls: ['./kiosk.page.scss'],
})
export class KioskPage implements OnInit {

  ngOnInit() {
  }
  locked = false;
  unLockCode = '';
  waivers = []
  bundles = [];
  public keys = {
    apiName: '',
    apiKey: '',
    unlock: '',
    url: 'https://my.1risk.net/'
  };
  localWaiverCnt = 0;
  bundleFilter = true;
  bundleSort = 'bundleName';

  constructor(private router: Router, /*public navCtrl: NavController, public navParams: NavParams, */public loadingCtrl: LoadingController, public alertCtrl: AlertController, public kioskService: KioskService, public events: EventsService) {
    kioskService.load()
      .then(data => {
        this.waivers = data as any;
        this.locked = false;

        kioskService.loadBundles().then(data => {
          this.bundles = data as any;
          if ((this.bundles.length > 0) || (this.waivers.length > 0)) {
            this.locked = true;
          }
        })
      });

    kioskService.loadKeys()
      .then(data => {
        this.keys = data as any;
      });
  }

  ionViewDidEnter() {
    this.events.publish('KIOSKLoaded', {});
  }

  connect() {
    console.log(this.keys);
    this.keys.apiName = this.keys.apiName.toLowerCase();
    this.kioskService.loadActiveWaiverList(this.keys)
      .then(data => {
        this.waivers = data as any;
        if (this.waivers) {
          this.kioskService.pullLookupsFromApi('states');
          this.kioskService.pullLookupsFromApi('countries');
          this.kioskService.pullLookupsFromApi('relationships');
        } else {
          this.waivers = [];
        }
      })

    this.kioskService.loadActiveBundlesList(this.keys).then(data => {
      this.bundles = data as any;
      if (this.bundles) {

      } else {
        this.bundles = [];
      }
    })
  }

  select(i) {
    this.waivers[i].selected = !this.waivers[i].selected;
  }

  selectBundle(i) {
    this.bundles[i].selected = !this.bundles[i].selected;
  }

  all() {
    for (var i = 0; i < this.waivers.length; i++) {
      this.waivers[i].selected = !this.waivers[i].selected;
    }
  }

  allBundles() {
    for (var i = 0; i < this.bundles.length; i++) {
      this.bundles[i].selected = !this.bundles[i].selected;
    }
  }


  async lockIn() {
    //get checked waivers and pull definitions.
    /*
    this.kioskService.lockIn();
    this.unLockCode = '';
    this.locked = true;
    let loader = this.loadingCtrl.create({
      spinner: 'ios',
      content: 'Setting Waivers Up...'
    });
    loader.present();
    console.log('Num Waivers', this.waivers.length);
    setTimeout(() => {
      loader.dismissAll();
      this.navCtrl.push(WaiversPage);
    }, 2000 * this.waivers.length);
    */

    const loader = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'Loading Data...'
    });

    loader.present();
    this.unLockCode = '';
    this.locked = true;
    this.kioskService.lockIn().then(val => {
      loader.dismiss();

      this.router.navigate(['/waivers']);
    });
  }

  unlock() {
    if (this.unLockCode === '9MCxCPW4HVBJKwn3') {
      this.locked = false;
    } else if (this.unLockCode === this.keys.unlock) {
      this.locked = false;
    } else {
      this.incorrectCode();
    }
    this.kioskService.getWaiverLocalCount().then(val => {
      this.localWaiverCnt = val as any;
    });
  }

  async uploadLocalWaivers() {
    const loader = await this.loadingCtrl.create({
      spinner: 'bubbles',
      message: 'Uploading Local Waivers...'
    });
    loader.present();
    this.kioskService.uploadLocalWaivers().then(val => {
      this.kioskService.getWaiverLocalCount().then(val => {
        this.localWaiverCnt = val as any;
        loader.dismiss();
      });
    })
  }

  async incorrectCode() {
    let alert = await this.alertCtrl.create({
      header: "Incorrect Code",
      message: "The unlock code entered is incorrect.",
		  buttons: ['Ok']
		})
		alert.present();
  }
}



